export const formatUtil = {};

formatUtil.getTel = tel => {
  if (!tel) {
    return '';
  }
  const cleaned = ('' + tel).replace(/\D/g, '');
  let match;
  if (cleaned.length === 9) {
    match = cleaned.match(/^(\d{2})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  } else if (cleaned.length === 10) {
    match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  } else if (cleaned.length === 11) {
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }
  return tel;
};

formatUtil.getMaskTel = tel => {
  if (!tel) {
    return '';
  }
  const cleaned = ('' + tel).replace(/\D/g, '');
  let match;
  if (cleaned.length === 9) {
    match = cleaned.match(/^(\d{2})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${'*'.repeat(match[2].length)}-${match[3]}`;
    }
  } else if (cleaned.length === 10) {
    match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${'*'.repeat(match[2].length)}-${match[3]}`;
    }
  } else if (cleaned.length === 11) {
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${'*'.repeat(match[2].length)}-${match[3]}`;
    }
  }
  return tel;
};

formatUtil.getMaskName = name => {
  if (!name || name.length === 0) {
    return '';
  }

  const nameLength = name.length;

  if (nameLength === 1) {
    return '*';
  } else if (nameLength === 2) {
    return name[0] + '*';
  } else {
    const firstChar = name[0];
    const lastChar = name[nameLength - 1];
    const middleChars = name.slice(1, -1).replace(/./g, '*');
    return firstChar + middleChars + lastChar;
  }
};

formatUtil.getMobile = function (source) {
  if (source == null) {
    return null;
  } else {
    return source.replace(/^(\d\d\d?)-?(\d\d\d\d?)-?(\d\d\d\d)$/, '$1-$2-$3');
  }
};

formatUtil.getNumber = function (v) {
  if (v == null || v === '') {
    return '0';
  }
  v = parseInt(v, 10).toString();
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  return v.toString().replace(regexp, ',');
};

formatUtil.getHourMinute = function (source, dateSeparator) {
  if (source == null) {
    return null;
  }
  if (dateSeparator == null) {
    dateSeparator = '';
  }
  if (typeof source == 'string') {
    source = source.trim();
    if (/^(....).(..).(..).(..).(..).(..)$/.test(source)) {
      return source.replace(/^(....).(..).(..).(..).(..).(..)$/, '$4:$5');
    } else if (/^(....)(..)(..)(..)(..)(..)$/.test(source)) {
      return source.replace(/^(....)(..)(..)(..)(..)(..)$/, '$4:$5');
    } else if (/^(..)(..)(..)(...)$/.test(source)) {
      return source.replace(/^(..)(..)(..)(...)$/g, '$1:$2');
    } else if (/^(..)(..)(..)$/.test(source)) {
      return source.replace(/^(..)(..)(..)$/g, '$1:$2');
    } else if (/^(..)(..)$/.test(source)) {
      return source.replace(/^(..)(..)$/g, '$1:$2');
    } else if (!isNaN(Date.parse(source))) {
      return formatUtil.getHourMinute(new Date(source), dateSeparator);
    } else {
      return source;
    }
  } else if (source.getFullYear) {
    var HH = source.getHours() < 10 ? '0' + source.getHours() : source.getHours();
    var minutes = source.getMinutes() < 10 ? '0' + source.getMinutes() : source.getMinutes();
    return ''.concat(HH).concat(':').concat(minutes);
  } else {
    return source;
  }
};

// formatUtil.getDate(new Date());    ==> 20220519
// formatUtil.getDate('2020-08-01');  ==> 20200801
// formatUtil.getDate('2020-08-01', '.');  ==> 2020.08.01
formatUtil.getDate = function (source, dateSeparator) {
  if (source == null) {
    return null;
  }
  if (dateSeparator == null) {
    dateSeparator = '';
  }
  if (typeof source == 'string') {
    source = source.trim();
    if (/^[0-9]{8}.*/.test(source)) {
      return source.replace(/^(....)(..)(..).*/g, '$1' + dateSeparator + '$2' + dateSeparator + '$3');
    } else if (/^(....).?(..).?(..).*/.test(source)) {
      return source.replace(/^(....).?(..).?(..).*/, '$1' + dateSeparator + '$2' + dateSeparator + '$3');
    } else {
      return source;
    }
  } else if (source.getFullYear) {
    var yyyy = source.getFullYear();
    var mm = source.getMonth() < 9 ? '0' + (source.getMonth() + 1) : source.getMonth() + 1; // getMonth() is zero-based
    var dd = source.getDate() < 10 ? '0' + source.getDate() : source.getDate();
    return ''.concat(yyyy).concat(dateSeparator).concat(mm).concat(dateSeparator).concat(dd);
  } else {
    return source;
  }
};

formatUtil.getTime = function (source) {
  if (source == null) {
    return null;
  }
  source = source.trim();
  if (/^(....).(..).(..).(..).(..).(..)$/.test(source)) {
    return source.replace(/^(....).(..).(..).(..).(..).(..)$/, '$4:$5:$6');
  } else if (/^(....)(..)(..)(..)(..)(..)$/.test(source)) {
    return source.replace(/^(....)(..)(..)(..)(..)(..)$/, '$4:$5:$6');
  } else if (/^(..)(..)(..)(...)$/.test(source)) {
    return source.replace(/^(..)(..)(..)(...)$/g, '$1:$2:$3.$4');
  } else if (/^(..)(..)(..)$/.test(source)) {
    return source.replace(/^(..)(..)(..)$/g, '$1:$2:$3');
  } else if (/^(..)(..)$/.test(source)) {
    return source.replace(/^(..)(..)$/g, '$1:$2');
  } else {
    return source;
  }
};
formatUtil.getMinute = function (source) {
  if (source == null) {
    return null;
  }
  source = source.trim();
  if (/^(....).(..).(..).(..).(..).(..)$/.test(source)) {
    return source.replace(/^(....).(..).(..).(..).(..).(..)$/, '$4:$5');
  } else if (/^(....)(..)(..)(..)(..)(..)$/.test(source)) {
    return source.replace(/^(....)(..)(..)(..)(..)(..)$/, '$4:$5');
  } else if (/^(..)(..)(..)(...)$/.test(source)) {
    return source.replace(/^(..)(..)(..)(...)$/g, '$1:$2');
  } else if (/^(..)(..)(..)$/.test(source)) {
    return source.replace(/^(..)(..)(..)$/g, '$1:$2');
  } else if (/^(..)(..)$/.test(source)) {
    return source.replace(/^(..)(..)$/g, '$1:$2');
  } else {
    return source;
  }
};

// formatUtil.getDatetime('20220624185218', '.'); ==> '2022.06.24 18:52:18'
// formatUtil.getDatetime('2022-06-24 18:52:18', '.'); ==> '2022.06.24 18:52:18'
// formatUtil.getDatetime('2022-06-24T09:52:16.000+00:00', '-'); ==> '2022.06.24 18:52:18'
formatUtil.getDatetime = function (source, dateSeparator = '-') {
  if (source == null) {
    return null;
  }
  if (dateSeparator == null) {
    dateSeparator = '-';
  }
  if (typeof source == 'string') {
    source = source.trim();
    if (/^(....).(..).(..).(..).(..).(..)$/.test(source)) {
      return source.replace(/^(....).(..).(..).(..).(..).(..)$/, '$1' + dateSeparator + '$2' + dateSeparator + '$3 $4:$5:$6');
    } else if (/^(....)(..)(..)(..)(..)(..)$/.test(source)) {
      return source.replace(/^(....)(..)(..)(..)(..)(..)$/, '$1' + dateSeparator + '$2' + dateSeparator + '$3 $4:$5:$6');
    } else if (!isNaN(Date.parse(source))) {
      return formatUtil.getDatetime(new Date(source), dateSeparator);
    } else {
      return source;
    }
  } else if (source.getFullYear) {
    var yyyy = source.getFullYear();
    var mm = source.getMonth() < 9 ? '0' + (source.getMonth() + 1) : source.getMonth() + 1; // getMonth()
    // is
    // zero-based
    var dd = source.getDate() < 10 ? '0' + source.getDate() : source.getDate();
    var HH = source.getHours() < 10 ? '0' + source.getHours() : source.getHours();
    var minutes = source.getMinutes() < 10 ? '0' + source.getMinutes() : source.getMinutes();
    var seconds = source.getSeconds() < 10 ? '0' + source.getSeconds() : source.getSeconds();
    return ''.concat(yyyy).concat(dateSeparator).concat(mm).concat(dateSeparator).concat(dd).concat(' ').concat(HH).concat(':').concat(minutes).concat(':').concat(seconds);
  } else {
    return source;
  }
};

formatUtil.strSeparator = function (dateStr, s = '-') {
  if (!dateStr) {
    return '';
  }
  if (dateStr.length === 8) {
    return `${dateStr.substring(0, 4)}${s}${dateStr.substring(4, 6)}${s}${dateStr.substring(6, 8)}`;
  } else {
    return 'Invalid date format';
  }
};

/**
 * 개행문자를 Br태그로 변경
 */
formatUtil.returnToBr = function (source) {
  if (source == null) {
    return null;
  }
  return source.replace(/\r\n/g, '<br/>').replace(/\r/g, '<br/>').replace(/\n/g, '<br/>');
};

formatUtil.getElapsedTime = function (milliseconds) {
  var sec = Math.round(milliseconds / 1000);
  var min = Math.round(sec / 60);
  var hour = Math.round(min / 60);
  return new String(hour).padStart(2, '0') + ':' + new String(min % 60).padStart(2, '0') + ':' + new String(sec % 60).padStart(2, '0');
};

// formatUtil.lpad(3, 2, '0') ==> '03'
formatUtil.lpad = function (source, targetLength, padString) {
  const strSource = String(source);
  if (strSource.length >= targetLength) {
    return strSource;
  } else {
    if (padString == null || padString == ' ') {
      padString = ' ';
    } else if (padString.length > 1) {
      padString = padString.substring(0, 1);
    }
    targetLength = targetLength - strSource.length;
    var prefix = '';
    for (var i = 0; i < targetLength; i++) {
      prefix += padString;
    }
    return prefix + strSource;
  }
};

formatUtil.getBizNo = function (num, type) {
  var formatNum = '';
  try {
    if (num.length == 10) {
      if (type == 0) {
        formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
      } else {
        formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
      }
    }
  } catch (e) {
    formatNum = num;
  }
  return formatNum;
};

formatUtil.numberToKorean = num => {
  if (num === '') {
    return '';
  }
  let str = num.toString();
  let result = '';
  let len = str.length;

  // 억 단위 처리
  if (len > 8) {
    const eokUnit = parseInt(str.slice(0, len - 8));
    if (eokUnit > 0) {
      result += `${formatUtil.getNumber(eokUnit)}억 `;
    }
    str = str.slice(len - 8);
    len = str.length;
  }

  // 만 단위 처리
  if (len > 4) {
    const manUnit = parseInt(str.slice(0, len - 4));
    if (manUnit > 0) {
      result += `${formatUtil.getNumber(manUnit)}만 `;
    }
    str = str.slice(len - 4);
  }

  // 나머지 처리
  const lowerUnit = parseInt(str);
  if (lowerUnit !== 0) {
    result += `${formatUtil.getNumber(lowerUnit)}원`;
  } else if (result !== '') {
    result = result.trim() + '원';
  }

  return result.trim();
};

formatUtil.getDayOfWeek = dateString => {
  // dateString should be in 'yyyyMMdd' format, e.g., '20240710'
  var year = parseInt(dateString.substring(0, 4), 10);
  var month = parseInt(dateString.substring(4, 6), 10) - 1; // JS months are zero-indexed
  var day = parseInt(dateString.substring(6, 8), 10);

  var date = new Date(year, month, day);
  //var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var days = ['일', '월', '화', '수', '목', '금', '토'];
  return days[date.getDay()]; // getDay() returns a number from 0 to 6
};

formatUtil.getKorDate = inputDate => {
  // 입력이 문자열인 경우만 처리
  if (typeof inputDate !== 'string') {
    return inputDate;
  }

  // 정규식을 사용하여 입력 형식 확인
  let dateRegex = /^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/;
  let match = inputDate.match(dateRegex);

  if (match) {
    let year = match[1];
    let month = match[2];
    let day = match[3];

    // 변환된 날짜 형식 반환
    return `${year}년${month}월${day}일`;
  } else {
    return inputDate;
  }
};

formatUtil.getKorMonthDate = inputDate => {
  // 입력이 문자열인 경우만 처리
  if (typeof inputDate !== 'string') {
    return inputDate;
  }

  // 정규식을 사용하여 입력 형식 확인
  let dateRegex = /^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/;
  let match = inputDate.match(dateRegex);

  if (match) {
    let month = match[2];
    let day = match[3];

    // 변환된 날짜 형식 반환
    return `${month}월 ${day}일`;
  } else {
    return inputDate;
  }
};

formatUtil.getExtractDay = dateString => {
  // 날짜를 Date 객체로 변환
  const date = new Date(dateString);

  // 날짜(일)를 추출
  const day = date.getDate();

  // 날짜(일)을 반환 (자동으로 앞의 0은 제거됨)
  return day;
};

//getWeekNumber(20241203) 20241203은 해당년도 몇번쨰 주인지?
formatUtil.getWeekNumber = dateString => {
  const year = parseInt(dateString.slice(0, 4), 10);
  const month = parseInt(dateString.slice(4, 6), 10) - 1; // JavaScript의 월은 0부터 시작합니다.
  const day = parseInt(dateString.slice(6, 8), 10);
  const date = new Date(year, month, day);

  // 연도의 첫 번째 날을 가져옵니다.
  const firstDayOfYear = new Date(year, 0, 1);

  // 주어진 날짜와 연도 첫날 사이의 일수를 계산합니다.
  const daysBetween = Math.floor((date - firstDayOfYear) / (1000 * 60 * 60 * 24));

  // 주 번호를 계산합니다.
  const weekNumber = Math.ceil((daysBetween + firstDayOfYear.getDay() + 1) / 7);
  return weekNumber;
};

//getYearOfWeek(2023, 30) 2023년 30번째주는 몇년도?
formatUtil.getYearOfWeek = (startYear, weekNumber) => {
  // 주어진 연도의 첫 번째 날
  const firstDayOfYear = new Date(startYear, 0, 1);

  // 첫 주의 첫 번째 날을 구합니다. (여기서는 월요일을 기준으로 합니다.)
  const startOfFirstWeek = new Date(firstDayOfYear);
  startOfFirstWeek.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1);

  // 해당 주 번호의 첫 번째 날을 계산합니다.
  const startOfGivenWeek = new Date(startOfFirstWeek);
  startOfGivenWeek.setDate(startOfFirstWeek.getDate() + (weekNumber - 1) * 7);

  // 해당 주 번호의 연도를 반환합니다.
  return startOfGivenWeek.getFullYear();
}
