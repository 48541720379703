import { useEffect } from 'react';

import { Api } from '@/api/index';
import { Autocomplete, AutocompleteItem, Select, SelectItem } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';

export function GroupSelect({ label, selectedKey, onChange, name, onFirstLoad, firstVal }) {
  const { data: groupList, isLoading } = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data.groupList,
  });

  useEffect(() => {
    if (onFirstLoad && groupList?.length > 0) {
      onFirstLoad(firstVal ? firstVal : groupList[0].id);
    }
  }, [groupList]);

  return (
    <Select
      size="sm"
      labelPlacement="outside"
      label={label}
      placeholder=" "
      disallowEmptySelection
      className="min-w-48 max-w-48"
      selectedKeys={[String(selectedKey)]}
      onChange={onChange}
      name={name}
      isLoading={isLoading}
    >
      {groupList &&
        groupList.map(item => {
          return <SelectItem key={item.id}>{item.name}</SelectItem>;
        })}
    </Select>
  );
}

export function PlaceSelect({ label, selectedKey, onChange, name, groupId, onFirstLoad, noAll }) {
  const { data: placeList, isLoading } = useQuery({
    queryKey: ['placeList', groupId],
    queryFn: () => Api.placeList({ pndGroupId: groupId, pageSize: 1000 }),
    select: res => {
      return res?.data?.data?.placePage?.content?.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    },
    enabled: !!groupId,
  });

  useEffect(() => {
    if (onFirstLoad && placeList?.length > 0) {
      onFirstLoad(placeList[0].id);
    }
  }, [placeList]);
  return (
    <>
      <Autocomplete
        size="sm"
        labelPlacement="outside"
        isLoading={isLoading}
        selectedKey={String(selectedKey)}
        label={label}
        placeholder={label + '를 선택해주세요'}
        onSelectionChange={key => onChange({ target: { name, value: key } })}
        classNames={{ base: '[&>div>div]:h-auto' }}
        className="min-w-56 max-w-56"
        selectionMode="single"
        listboxProps={{emptyContent:"검색 결과가 없습니다."}}
      >
        {placeList && placeList.map(item => {
          return (
            <AutocompleteItem key={item.id} value={item.name}>
              {item.name}
            </AutocompleteItem>
          );
        })}
      </Autocomplete>
      {/* <Select
        size="sm"
        labelPlacement="outside"
        label={label}
        placeholder=" "
        disallowEmptySelection
        className="min-w-56 max-w-56"
        selectedKeys={[String(selectedKey)]}
        items={placeList ? placeList : []}
        onChange={onChange}
        name={name}
        isLoading={isLoading}
        classNames={{
          listboxWrapper: 'max-h-[500px]',
        }}
      >
        {!noAll && <SelectItem key={''}>전체</SelectItem>}
        {placeList &&
          placeList.map(item => {
            return <SelectItem key={item.id}>{item.name}</SelectItem>;
          })}
      </Select> */}
    </>
  );
}

export function PackageSelect({ selectedKey, onChange, name, groupId, onFirstLoad, noAll }) {
  const { data: packageList, isLoading } = useQuery({
    queryKey: ['packages', groupId],
    queryFn: () => Api.packages({ pndGroupId: groupId }),
    select: res => {
      return res?.data?.data?.jobPlanPackageList?.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    },
    enabled: !!groupId,
  });

  useEffect(() => {
    if (onFirstLoad && packageList?.length > 0) {
      onFirstLoad(packageList[0].id);
    }
  }, [packageList]);
  return (
    <Select
      size="sm"
      labelPlacement="outside"
      label={'운송 물품'}
      placeholder=" "
      disallowEmptySelection
      className="max-w-48"
      defaultSelectedKeys={['']}
      selectedKeys={[selectedKey]}
      items={packageList ? packageList : []}
      onChange={onChange}
      name={name}
      isLoading={isLoading}
    >
      {!noAll && <SelectItem key={''}>전체</SelectItem>}
      {packageList &&
        packageList.map(item => {
          return <SelectItem key={item.id}>{item.name}</SelectItem>;
        })}
    </Select>
  );
}
