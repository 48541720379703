import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import LoadingBackground from '@/components/LoadingBackground';
import PaginationTable from '@/components/PaginationTable';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import useDebounce from '@/hooks/useDebounce';
import { formatUtil } from '@/utils/FormatUtil';
import { Chip, Input, Select, SelectItem, Spinner, getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalAdd from './ModalAdd';
import ModalView from './ModalView';
import ModalUpdate from './ModalUpdate';
import { GroupSelect } from '@/components/common/filter';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '차량구분', uid: 'vehicleFlagName' },
  { name: '차량이름', uid: 'name' },
  { name: '차량번호', uid: 'vehicleNo' },
  { name: '차량유형', uid: 'typeName' },
  { name: '기사명', uid: 'driverName' },
  { name: '차주 전화번호', uid: 'ownerTel' },
  { name: '활성화', uid: 'useYn' },
  { name: '등록일', uid: 'regDt' },
];
const search = [
  { value: 'vehicleName', label: '차량이름' },
  { value: 'vehicleNo', label: '차량번호' },
  { value: 'driverName', label: '기사명' },
  { value: 'ownerTel', label: '차주 전화번호' },
];
const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();
  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onOpenChange: onOpenChangeUpdate, onClose: onCloseUpdate } = useDisclosure();

  const selectedId = useRef('');
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    searchType: searchParams.get('searchType') || 'vehicleName',
    searchText: searchParams.get('searchText') || '',
    useYn: searchParams.get('useYn') || '',
  };
  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const debounce = useDebounce(params.searchText, 500);

  const [textLoading, setTextLoading] = useState(false);

  useEffect(() => {
    if (debounce != params.searchText) {
      setTextLoading(true);
    } else {
      setTextLoading(false);
    }
  }, [debounce, params.searchText]);

  const fetchVehicleList = () => {
    const postParams = { pageNo: params.pageNo, pageSize: params.pageSize };
    postParams.useYn = params.useYn;
    postParams.pndGroupId = params.pndGroupId;
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    const trimParams = Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
    return Api.vehicleList(trimParams);
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['vehicleList', { ...queryKey, searchText: debounce }]);
  };

  const listQuery = useQuery({
    queryKey: ['vehicleList', { ...queryKey, searchText: debounce }],
    queryFn: fetchVehicleList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
    enabled: !!params.pndGroupId,
  });

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(listQuery?.data?.vehiclePage?.totalElements ? listQuery?.data?.vehiclePage?.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'name': {
          return <div>{item?.name ? item?.name : '-'}</div>;
        }

        case 'vehicleFlagName':
          return <div>{item.vehicleFlagName ? item.vehicleFlagName : '-'}</div>;
        case 'driverName':
          return <div>{item.driverName ? formatUtil.getMaskName(item.driverName, '-') : '-'}</div>;

        case 'typeName':
          return <div>{item.typeName ? item.typeName : '-'}</div>;

        case 'ownerTel':
          return <div>{item.ownerTel ? formatUtil.getMaskTel(item.ownerTel, '-') : '-'}</div>;

        case 'tel':
          return (
            <Chip variant="light" color={'default'}>
              {formatUtil.getMaskTel(getKeyValue(item, columnKey), '-')}
            </Chip>
          );

        case 'useYn':
          return item.useYn === 'Y' ? (
            <Chip size="sm" variant="flat" color={'success'}>
              활성
            </Chip>
          ) : (
            <Chip size="sm" variant="flat" color={'default'}>
              비활성
            </Chip>
          );
        case 'regDt':
          return (
            <Chip variant="light" color={'default'}>
              {formatUtil.getDate(getKeyValue(item, columnKey), '-')}
            </Chip>
          );
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [listQuery?.data?.vehiclePage?.totalElements]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const handlerAddClick = () => {
    onOpenAdd();
  };
  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  return (
    <ContentWrapper>
      {listQuery.isFetching && <LoadingBackground />}
      <ModalAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadList}></ModalAdd>
      {isOpenView && (
        <ModalView
          isOpenView={isOpenView}
          onOpenChangeView={onOpenChangeView}
          onCloseView={onCloseView}
          selectedId={selectedId.current}
          reloadList={reloadList}
          onOpenView={onOpenView}
          onOpenUpdate={onOpenUpdate}
        ></ModalView>
      )}

      <ModalUpdate
        isOpenUpdate={isOpenUpdate}
        onOpenChangeUpdate={onOpenChangeUpdate}
        onCloseUpdate={onCloseUpdate}
        size="5xl"
        selectedId={selectedId.current}
        scrollBehavior={'outside'}
        onOpenView={onOpenView}
      />
      <div className="flex flex-col gap-4 p-4 mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 rounded-medium">
        <div className="flex gap-4">
          <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
          <Select size="sm" labelPlacement="outside" disallowEmptySelection label="활성화" className="max-w-48" selectedKeys={[params.useYn]} onChange={onInputChange} name="useYn">
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="Y">활성</SelectItem>
            <SelectItem key="N">비활성</SelectItem>
          </Select>
        </div>
        <div className="flex gap-4">
          <Input
            labelPlacement="outside"
            label="검색"
            startContent={
              <Select
                disallowEmptySelection
                className="w-32 shrink-0"
                classNames={{
                  base: 'h-full items-center flex-row -mx-2',
                  trigger: '!bg-transparent !shadow-none h-full min-h-min',
                  mainWrapper: 'h-full',
                }}
                selectedKeys={[params.searchType]}
                items={search}
                onChange={onInputChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
            }
            size="sm"
            className="w-full"
            placeholder="검색어를 입력하세요"
            onChange={onInputChange}
            value={params.searchText}
            name="searchText"
            endContent={textLoading && <Spinner size="sm" color="primary" />}
          />
        </div>
      </div>

      <PaginationTable
        data={{
          page: params.pageNo,
          size: params.pageSize,
          totalPages: listQuery?.data?.vehiclePage.totalPages,
          totalElements: listQuery?.data?.vehiclePage.totalElements,
          columns: columns,
          items: listQuery?.data?.vehiclePage.content,
        }}
        renderCell={renderCell}
        isFetching={listQuery.isFetching}
        isLoading={listQuery.isLoading}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={handlerRowClick}
        onAddClick={handlerAddClick}
      />
    </ContentWrapper>
  );
};

export default List;
