import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import LoadingBackground from '@/components/LoadingBackground';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import PaginationTable from '@/components/PaginationTable';
import { GroupSelect, PackageSelect } from '@/components/common/filter';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import useDebounce from '@/hooks/useDebounce';
import { ExcelDownloadUtil } from '@/utils/ExcelDownloadUtil';
import { formatUtil } from '@/utils/FormatUtil';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { Button, Chip, DateRangePicker, Input, Select, SelectItem, Spinner, getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalView from './ModalView';
import { status, statusColor, statusIcon } from '@/constants/planStatus';
import DynamicIcon from '@/components/DynamicIcon';
import CustomDateRangePicker from '@/components/CustomDateRagePicker';
// if (column.ascending === params.orderType) {
//   setSortDescriptor({
//     column: column.uid,
//     direction: "descending",
//   });
// }
// if (column.descending === params.orderType) {
const columns = [
  { name: 'No', uid: 'no' },
  { name: '스케줄번호', uid: 'jobUnitId' },
  { name: '운송상태', uid: 'planStatus', sortable: true, ascending: '31', descending: '30' },
  { name: '상차지', uid: 'pickupPlaceName' },
  { name: '하차지', uid: 'deliveryPlaceName' },
  { name: '차량번호', uid: 'vehicleNo' },
  { name: '기사', uid: 'driverName' },
  { name: '운송유형', uid: 'type' },
  { name: '운송물품', uid: 'jobPlanPackageName' },
  { name: '총 단가(원)', uid: 'price' },
  { name: '무게(kg)', uid: 'weight' },
  { name: '계근표', uid: 'imageExist' },
  { name: '하차완료일시', uid: 'deliveryDt', sortable: true, ascending: '21', descending: '20' },
  { name: '운송일자', uid: 'planDate' },
];

const columnWidths = [50, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100];

const excelCol = [
  { v: 'No', t: 's' },
  { v: '스케줄번호', t: 's' },
  { v: '운송상태', t: 's' },
  { v: '상차지', t: 's' },
  { v: '하차지', t: 's' },
  { v: '차량번호', t: 's' },
  { v: '기사', t: 's' },
  { v: '운송유형', t: 's' },
  { v: '운송물품', t: 's' },
  { v: '총 단가(원)', t: 's' },
  { v: '무게(kg)', t: 's' },
  { v: '운송일자', t: 's' },
];

const statusObj = [
  {
    value: '',
    label: '전체',
  },
  {
    value: 'READY',
    label: '배차완료',
  },
  {
    value: 'PICKUP',
    label: '상차완료',
  },
  {
    value: 'DELIVERY',
    label: '하차완료',
  },
  {
    value: 'COMPLETE',
    label: '구매확정',
  },
  // {
  //   value: 'FAIL',
  //   label: '운송실패',
  // },
  {
    value: 'DELIVERY_FAIL',
    label: '하차실패',
  },
  {
    value: 'PICKUP_FAIL',
    label: '상차실패',
  },
  // {
  //   value: 'SCHEDULE_CANCEL',
  //   label: '스케줄취소',
  // },
];

const type = {
  CARGO_DELIVERY: '화물운반',
  WASTE_COLLECT: '폐기물수거',
};

const searchType = [
  { value: 'pickupPlaceNameLike', label: '상차지' },
  { value: 'deliveryPlaceNameLike', label: '하차지' },
  { value: 'driverNameLike', label: '기사이름' },
  { value: 'vehicleNoLike', label: '차량번호' },
];

const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();

  const { callAlert } = useAlertModal();

  const [sortDescriptor, setSortDescriptor] = useState({});

  let defaultDate = today(getLocalTimeZone());
  const afterOneMonth = defaultDate.add({ months: 1 });
  const beforeOneMonth = defaultDate.add({ months: -1 });
  const selectedId = useRef('');
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    planStatus: searchParams.get('planStatus') || '',
    type: searchParams.get('type') || '',
    jobPlanPackageId: searchParams.get('jobPlanPackageId') || '',
    dateStart: searchParams.get('dateStart') || String(beforeOneMonth).replaceAll('-', ''),
    dateEnd: searchParams.get('dateEnd') || String(defaultDate).replaceAll('-', ''),
    searchType: searchParams.get('searchType') || 'pickupPlaceNameLike',
    searchText: searchParams.get('searchText') || '',
    like: searchParams.get('like') || '',
    orderType: searchParams.get('orderType'), // 기본값 설정
  };

  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const debounce = useDebounce(params.searchText, 500);

  const [textLoading, setTextLoading] = useState(false);

  useEffect(() => {
    if (debounce != params.searchText) {
      setTextLoading(true);
    } else {
      setTextLoading(false);
    }
  }, [debounce, params.searchText]);

  const getParams = (pageNo, pageSize) => {
    let postParams = { pageNo: pageNo, pageSize: pageSize };
    postParams.pndGroupId = params.pndGroupId;
    postParams.planStatus = params.planStatus;
    postParams.jobPlanPackageId = params.jobPlanPackageId;
    postParams.type = params.type;
    postParams.dateStart = params.dateStart;
    postParams.dateEnd = params.dateEnd;
    postParams.like = params.like;
    postParams.orderType = params.orderType;
    postParams.planStatusNot = 'NOT_READY';
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    return Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
  };

  const handleSortChange = useCallback(
    item => {
      let newOrderType;
      if (item.column === 'planStatus') {
        newOrderType = sortDescriptor.direction === 'ascending' ? '30' : '31';
      } else if (item.column === 'planDate') {
        newOrderType = sortDescriptor.direction === 'ascending' ? '20' : '21';
      }
      setParams(prevParams => ({
        ...prevParams,
        orderType: newOrderType,
      }));
      setSortDescriptor({
        column: item.column,
        direction: sortDescriptor.direction === 'ascending' ? 'descending' : 'ascending',
      });
    },
    [sortDescriptor, setParams]
  );

  const fetchJobList = () => {
    const trimParams = getParams(params.pageNo, params.pageSize);
    return Api.jobList(trimParams);
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['jobList', { ...queryKey, searchText: debounce }]);
  };
  const {
    data: jobList,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['jobList', { ...queryKey, searchText: debounce }],
    queryFn: fetchJobList,
    placeholderData: keepPreviousData,
    enabled: !!params.pndGroupId,
    select: res => res.data.data,
  });

  const downloadData = async unitId => {
    const jobUnitIdList = jobList?.jobUnitPage?.content?.map(item => item.id);
    let jobUnitIds = unitId ? unitId : jobUnitIdList.join();
    return Api.jobImages({ groupId: params.pndGroupId, jobUnitIds: jobUnitIds });
  };

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      const jobPlan = item?.jobPlan ? item?.jobPlan : {};
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(jobList?.jobUnitPage?.totalElements ? jobList?.jobUnitPage?.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'jobUnitId': {
          return <div>{item.id}</div>;
        }

        case 'planStatus':
          return (
            <div className={`flex items-center gap-1 text-sm`}>
              <DynamicIcon className={statusColor[item.planStatus]} iconName={statusIcon[item.planStatus]} weight={'fill'}></DynamicIcon>
              <span className={statusColor[item.planStatus]}>{status[item.planStatus]}</span>
            </div>
          );
        case 'pickupPlaceName':
          return <div>{item.pickupPlaceName ? item.pickupPlaceName : '-'}</div>;
        case 'deliveryPlaceName':
          return <div>{item.deliveryPlaceName ? item.deliveryPlaceName : '-'}</div>;
        case 'vehicleNo':
          return <div>{jobPlan.vehicleNo ? jobPlan.vehicleNo : '-'}</div>;
        case 'driverName':
          return <div>{jobPlan.driverName ? jobPlan.driverName : '-'}</div>;
        case 'type':
          return (
            <Chip size="sm" variant="flat" className="text-default-500">
              {type[item.type]}
            </Chip>
          );
        case 'jobPlanPackageName':
          if (item?.jobPlanPackageName) {
            return (
              <Chip size="sm" variant="flat" className="text-default-500">
                {item.jobPlanPackageName}
              </Chip>
            );
          } else {
            return '-';
          }
        case 'price':
          return <div>{formatUtil.getNumber(Number(item.price || 0) + Number(item.price2 || 0))}</div>;
        case 'weight':
          return <div>{formatUtil.getNumber(item.weight)}</div>;

        case 'imageExist': {
          let downLoad = (
            <div>
              {item.imageExist || item.deliveryImageExist ? (
                <Chip
                  className="cursor-pointer"
                  color="primary"
                  variant="bordered"
                  onClick={e => {
                    e.stopPropagation();
                    handleImageDownload(item.id);
                  }}
                >
                  다운로드
                </Chip>
              ) : (
                '-'
              )}
            </div>
          );

          return downLoad;
        }
        case 'planDate':
          return <div>{formatUtil.getDate(jobPlan.planDate, '-')}</div>;
        case 'deliveryDt':
          return <div>{item.deliveryDt ? formatUtil.getDate(item.deliveryDt, '-') + ' ' + formatUtil.getTime(item.deliveryDt, '-') : '-'}</div>;
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [jobList?.jobUnitPage?.totalElements, params.pageNo]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const fetchJobListForExcel = () => {
    const trimParams = getParams(params.pageNo, 1000000);
    return Api.jobList(trimParams);
  };

  const excelDownload = useCallback(excelData => {
    const formattedData = excelData.map((data, idx) => {
      const jobPlan = data?.jobPlan ? data?.jobPlan : {};
      return [
        data.id,
        status[data.planStatus],
        data.pickupPlaceName ? data.pickupPlaceName : '-',
        data.deliveryPlaceName ? data.deliveryPlaceName : '-',
        jobPlan.vehicleNo ? jobPlan.vehicleNo : '-',
        jobPlan.driverName ? jobPlan.driverName : '-',
        type[data.type],
        data.jobPlanPackageName,
        Number(data.price || 0) + Number(data.price2 || 0),
        data.weight || 0,
        formatUtil.getDate(jobPlan.planDate, '-'),
      ];
    });

    ExcelDownloadUtil(formattedData, '운송내역', '운송내역', excelCol, columnWidths);
  }, []);

  const handleExcelDownload = async () => {
    try {
      const result = await fetchJobListForExcel();
      if (result.data.code === 'SUCC') {
        excelDownload(result.data.data.jobUnitPage.content);
      }
    } catch (error) {
      console.error('Excel download failed', error);
    }
  };

  //운반회사 최초로딩시
  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  const handleImageDownload = async unitId => {
    try {
      const response = await downloadData(unitId);
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'download'; // 기본 파일명

      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
          // URL 디코딩
          filename = decodeURIComponent(filename);
        }
      }
      // Content-Type 확인
      const contentType = response.headers['content-type'];

      // Blob 생성 및 파일 저장
      const blob = new Blob([response.data], { type: contentType });
      saveAs(blob, filename);
    } catch (error) {
      if (error.response) {
        const reader = new FileReader();
        reader.onload = function () {
          const errorMsg = JSON.parse(reader.result);
          if (errorMsg?.code == 'FAIL') {
            callAlert('등록된 계근표를 찾을수 없습니다.');
          }
          console.error('Server responded with:', errorMsg);
        };
        reader.readAsText(error.response.data);
      } else {
        console.error('Request failed:', error);
      }
    }
  };

  return (
    <ContentWrapper>
      {isFetching && <LoadingBackground />}
      <ModalView isOpenView={isOpenView} onOpenChangeView={onOpenChangeView} onCloseView={onCloseView} selectedId={selectedId.current} reloadList={reloadList} onOpenView={onOpenView}></ModalView>

      {/* <div className="py-2 text-xl">배차관리</div> */}
      <div className="flex flex-col gap-4 p-4 mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 rounded-medium">
        <div className="flex gap-4">
          <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
          <Select
            size="sm"
            labelPlacement="outside"
            placeholder=" "
            disallowEmptySelection
            label="운송상태"
            className="max-w-48"
            selectedKeys={[params.planStatus]}
            onChange={onInputChange}
            name="planStatus"
            items={statusObj}
          >
            {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
          </Select>
          <Select size="sm" labelPlacement="outside" placeholder=" " disallowEmptySelection label="운송유형" className="max-w-48" selectedKeys={[params.type]} onChange={onInputChange} name="type">
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="WASTE_COLLECT">폐기물수거</SelectItem>
            <SelectItem key="CARGO_DELIVERY">화물운반</SelectItem>
          </Select>
          <PackageSelect groupId={params.pndGroupId} name="jobPlanPackageId" selectedKey={String(params.jobPlanPackageId)} onChange={onInputChange}></PackageSelect>
          <CustomDateRangePicker
            setParams={setParams}
            size="sm"
            className="max-w-xs"
            label="운송일자"
            value={{
              start: parseDate(formatUtil.getDate(params.dateStart, '-')),
              end: parseDate(formatUtil.getDate(params.dateEnd, '-')),
            }}
          />
        </div>

        <div className="flex gap-4">
          <Input
            labelPlacement="outside"
            label="검색"
            size="sm"
            className="w-full"
            placeholder="검색어를 입력하세요"
            onChange={onInputChange}
            value={params.searchText}
            name="searchText"
            startContent={
              <Select
                disallowEmptySelection
                className="w-32 shrink-0"
                classNames={{
                  base: 'h-full items-center flex-row -mx-2',
                  trigger: '!bg-transparent !shadow-none h-full min-h-min',
                  mainWrapper: 'h-full',
                }}
                selectedKeys={[params.searchType]}
                items={searchType}
                onChange={onInputChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
            }
            endContent={textLoading && <Spinner size="sm" color="primary" />}
          />
        </div>
      </div>
      {jobList?.isLoading ? (
        <div className="flex items-center justify-center h-96">
          <Spinner></Spinner>
        </div>
      ) : (
        <PaginationTable
          type="single"
          data={{
            page: params.pageNo,
            size: params.pageSize,
            totalPages: jobList?.jobUnitPage.totalPages,
            totalElements: jobList?.jobUnitPage.totalElements,
            columns: columns,
            items: jobList?.jobUnitPage.content,
          }}
          params={params}
          setParams={setParams}
          renderCell={renderCell}
          isFetching={isFetching}
          isLoading={isLoading}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onRowClick={handlerRowClick}
          onExelDownload={handleExcelDownload}
          extrtaBtn1={
            <Button
              color="primary"
              variant="bordered"
              onClick={() => {
                handleImageDownload();
              }}
            >
              계근표 일괄 다운로드
            </Button>
          }
          noAdd={true}
        />
      )}
      <AlertModal />
    </ContentWrapper>
  );
};

export default List;
