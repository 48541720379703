import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDebounce from './useDebounce';

const useCustomSearchParams = initialParams => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateSearchParams = useCallback(setSearchParams, []);
  const [params, setParams] = useState({
    ...initialParams,
    pageNo: parseInt(searchParams.get('pageNo')) || 1,
    pageSize: parseInt(searchParams.get('pageSize')) || 30,
  });
  const queryKey = { ...params };

  useEffect(() => {
    const newParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value));
    updateSearchParams(new URLSearchParams(newParams));
  }, [params, updateSearchParams]);

  const onRowsPerPageChange = e => {
    setParams(prevParams => ({
      ...prevParams,
      pageSize: parseInt(e.target.value),
      pageNo: 1,
    }));
  };

  const onPageChange = pageNumber => {
    setParams(prevParams => ({
      ...prevParams,
      pageNo: pageNumber,
    }));
  };

  const onInputChange = e => {
    const { name, value } = e.target;

    setParams(prevParams => ({
      ...prevParams,
      [name]: value,
      pageNo: 1,
    }));
  };

  const removeParam = paramName => {
    setParams(prevParams => {
      const { [paramName]: _, ...rest } = prevParams;
      return {
        ...rest,
      };
    });
  };

  return {
    params,
    removeParam,
    setParams,
    queryKey,
    onRowsPerPageChange,
    onPageChange,
    onInputChange,
  };
};

export default useCustomSearchParams;
