import { Api } from '@/api';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList }) => {
  const { callAlert } = useAlertModal();

  const { data: jobCostQuery, isPending } = useQuery({
    queryKey: ['jobCost', selectedId],
    queryFn: () => Api.jobCostView({ jobCostId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = useInfiniteQuery({
    queryKey: ['jobCostDetails', selectedId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await Api.jobCostView({ jobCostId: selectedId, pageNo: pageParam, pageSize: 30 });
      return response.data.data;
    },
    getNextPageParam: lastPage => {
      const currentPage = lastPage.jobCostDetailPage.pageNo;
      const totalPages = lastPage.jobCostDetailPage.totalPages;
      console.log(totalPages, currentPage);
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: d =>
      d.pages
        .flatMap(page => page.jobCostDetailPage.content)
        .map((item, idx) => {
          item.no = idx + 1;
          return item;
        }),
    enabled: !!selectedId,
  });
  const [loaderRef, scrollerRef] = useInfiniteScroll({ hasMore: hasNextPage, onLoadMore: fetchNextPage });

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        {!isPending && (
          <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
            <ModalContent>
              {close => (
                <>
                  <ModalHeader>운송비 내역서</ModalHeader>
                  <ModalBody>
                    <FlexTable title={'기본정보'}>
                      <FlexTableRow>
                        <FlexTableHeader>등록일</FlexTableHeader>
                        <FlexTableData>{jobCostQuery.jobCost.regDate || '-'}</FlexTableData>
                        <FlexTableHeader>수정일</FlexTableHeader>
                        <FlexTableData>{jobCostQuery.jobCost.modDate || '-'}</FlexTableData>
                        <FlexTableHeader>작성자</FlexTableHeader>
                        <FlexTableData>{jobCostQuery.jobCost.regName || '-'}</FlexTableData>
                      </FlexTableRow>
                    </FlexTable>
                    <div className="grid grid-cols-2 gap-6">
                      <FlexTable title={'공급 받는자'}>
                        <FlexTableRow>
                          <FlexTableHeader>상호</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.bizName || '-'}</FlexTableData>
                          <FlexTableHeader>대표이사</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.bizCeo || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>등록번호</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.bizNo || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>주소</FlexTableHeader>
                          <FlexTableData>
                            <div>
                              <div>
                                <span>(우)</span>
                                <span>{jobCostQuery.jobCost?.bizZipCode}</span>
                              </div>
                              <div>
                                {jobCostQuery.jobCost?.bizAddr1} {jobCostQuery.jobCost?.bizAddr2}
                              </div>
                            </div>
                          </FlexTableData>
                        </FlexTableRow>
                      </FlexTable>
                      <FlexTable title={'차량 정보'}>
                        <FlexTableRow>
                          <FlexTableHeader>차주명</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.vehicleOwner || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>차주 전화번호</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.vehicleOwnerTel || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>차량번호</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.vehicleNoList?.length > 0 ? `${jobCostQuery.jobCost.vehicleNoList.map(el => el.vehicleNo).join(', ')}` : '-'}</FlexTableData>
                        </FlexTableRow>
                      </FlexTable>
                    </div>
                    <FlexTable>
                      <FlexTableHeader className="justify-between !text-base max-h-12 !text-default-800">
                        <div>합계(VAT포함)</div>
                        <div>{formatUtil.getNumber(jobCostQuery.jobCost.totalPrice + jobCostQuery.jobCost.totalVat || 0)}원</div>
                      </FlexTableHeader>
                    </FlexTable>
                    {/* 테이블 영역 */}
                    <div>
                      <div className="mb-2 text-sm font-medium text-default-600">{`운송내역 (${jobCostQuery.jobCostDetailPage.totalElements}건)`}</div>
                      <Table
                        isHeaderSticky
                        isCompact
                        baseRef={scrollerRef}
                        removeWrapper
                        aria-label="Job Cost Detail Table"
                        classNames={{
                          thead: '[&>tr]:first:shadow-none',
                          base: 'min-h-[160px] max-h-[160px] overflow-scroll',
                          // th: 'first:border-s last:border-e border-y',
                        }}
                        bottomContent={hasNextPage ? <div className="flex w-full" ref={loaderRef}></div> : null}
                      >
                        <TableHeader>
                          <TableColumn key="no">No</TableColumn>
                          <TableColumn key="vehicleNo">차량번호</TableColumn>
                          <TableColumn key="jobDate">운송일</TableColumn>
                          <TableColumn key="pickupPlaceName">상차지</TableColumn>
                          <TableColumn key="deliveryPlaceName">하차지</TableColumn>
                          <TableColumn key="weight">실중량(Kg)</TableColumn>
                          <TableColumn key="price">공급가액</TableColumn>
                          <TableColumn key="vat">새액</TableColumn>
                          <TableColumn key="etc">비고</TableColumn>
                        </TableHeader>
                        <TableBody items={data ?? []} emptyContent={'운송내역이 없습니다.'}>
                          {item => (
                            <TableRow key={item.id}>
                              <TableCell>{item.no}</TableCell>
                              <TableCell>{item.vehicleNo}</TableCell>
                              <TableCell>{item.jobDate}</TableCell>
                              <TableCell>{item.pickupPlaceName}</TableCell>
                              <TableCell>{item.deliveryPlaceName}</TableCell>
                              <TableCell>{formatUtil.getNumber(item.weight)}</TableCell>
                              <TableCell>{formatUtil.getNumber(item.price)} 원</TableCell>
                              <TableCell>{formatUtil.getNumber(item.vat)} 원</TableCell>
                              <TableCell>{item.etc ?? '-'}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="default" onPress={onCloseView}>
                      닫기
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        )}

        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <AlertModal />
      </>
    </>
  );
};

export default ModalView;
