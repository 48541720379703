import { Chip } from '@nextui-org/react';

export function CusChip({ color, children }) {
  return (
    <Chip
      classNames={{
        base: color?.bg,
        content: color?.text,
      }}
    >
      {children}
    </Chip>
  );
  //}
}
