import { Api } from '@/api/index';
import { CusChip } from '@/components/common/customChip';
import { GroupSelect } from '@/components/common/filter';
import ContentWrapper from '@/components/ContentWrapper';
import DynamicIcon from '@/components/DynamicIcon';
import { status, statusColorName } from '@/constants/planStatus';
import { formatUtil } from '@/utils/FormatUtil';
import { getLocalTimeZone, today } from '@internationalized/date';
import { Button, Card, CardBody, CardHeader, Divider, Listbox, ListboxItem } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { useTheme } from 'next-themes';

let data = [];

function View() {
  const { theme, setTheme } = useTheme();
  //const currentDate = dayjs();s
  const currentDate = today(getLocalTimeZone());

  const [date, setDate] = useState(currentDate);
  const [searchParams, setSearchParams] = useSearchParams();

  const [params, setParams] = useState({
    pndGroupId: '', // 초기 pndGroupId 설정
    date: date.toString().replaceAll('-', ''),
  });

  function formatDateToKoreanString(date) {
    const formatter = new Intl.DateTimeFormat('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const parts = formatter.formatToParts(new Date(date.toString()));

    const formattedDate = `${parts.find(part => part.type === 'year').value}년 ${parts.find(part => part.type === 'month').value}월 ${parts.find(part => part.type === 'day').value}일`;

    return formattedDate;
  }

  const queryKey = { ...params };

  useEffect(() => {
    setParams(prevParams => ({
      ...prevParams,
      date: date.toString().replaceAll('-', ''),
    }));
  }, [date, params.pndGroupId]);

  const nextDay = () => {
    setDate(date.add({ days: 1 }));
  };
  const prevDay = () => {
    setDate(date.add({ days: -1 }));
  };
  const getToday = () => {
    setDate(currentDate);
  };

  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  const handleGroupChange = e => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: e.target.value,
    }));
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={` ${theme === 'light' ? 'text-zinc-800' : 'text-zinc-300'}`}>
          <p className="label">{`${payload[0].name} : ${payload[0]?.payload?.tooltip ?? payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const { data: dashboardAlarm, isPending: dashboardAlarmPending } = useQuery({
    queryKey: ['dashboardAlarm', { ...queryKey }],
    queryFn: () => Api.dashboardAlarm(params),
    select: res => res.data.data,
    enabled: !!params.pndGroupId && !!params.date,
  });

  const { data: statusCount, isPending: statusPending } = useQuery({
    queryKey: ['statusCount', { ...queryKey }],
    queryFn: () => Api.statusCount(params),
    select: res => res.data.data,
    enabled: !!params.pndGroupId && !!params.date,
  });

  data = [];

  statusCount?.statusCountList?.forEach((item, idx) => {
    data.push({ name: status[item.planStauts], value: item.cnt, idx: idx });
  });

  let combinedAlarmList = [...(dashboardAlarm?.actionList || []), ...(dashboardAlarm?.scheduleChangeList || [])];
  combinedAlarmList.sort((a, b) => b.regDt.localeCompare(a.regDt));

  const [activeName, setActiveName] = useState(null);

  const handleClick = id => {
    if (activeName === id) {
      setActiveName(null);
    } else {
      setActiveName(id);
    }
  };

  let pickupSucc = data
    .filter(entry => {
      return entry.name == '상차완료';
    })
    .map(entry => {
      return { ...entry, color: '#84CC16' };
    });

  let pickupFail = data
    .filter(entry => {
      return entry.name == '상차실패';
    })
    .map(entry => {
      return { ...entry, color: '#FF5733' };
    });
  let deliverySucc = data
    .filter(entry => {
      return entry.name == '하차완료';
    })
    .map(entry => {
      return { ...entry, color: '#F97316' };
    });

  let deliveryFail = data
    .filter(entry => {
      return entry.name == '하차실패';
    })
    .map(entry => {
      return { ...entry, color: '#FF5733' };
    });

  let completeSucc = data
    .filter(entry => {
      return entry.name == '구매확정';
    })
    .map(entry => {
      return { ...entry, color: '#3B82F6' };
    });

  let chartPhrase;
  let allObj = [];

  if (activeName == 'PICKUP') {
    allObj = [...(pickupSucc || []), ...(pickupFail || [])];
    chartPhrase = '상차 대기중 입니다.';
  } else if (activeName == 'DELIVERY') {
    allObj = [...(deliverySucc || []), ...(deliveryFail || [])];
    chartPhrase = '하차 대기중 입니다.';
  } else if (activeName == 'COMPLETE') {
    allObj = [...(completeSucc || [])];
    chartPhrase = '구매확정 을 기다리고 있습니다.';
  }

  let chartObj = [...allObj];
  const totalAmount = chartObj.reduce((total, item) => {
    return total + item.value;
  }, 0);
  let notYetStartCnt = statusCount?.planTotalCount;
  chartObj.push({ name: '예정 스케줄', value: notYetStartCnt - totalAmount, tooltip: notYetStartCnt, color: '#CCCCCC' });

  const value = data?.find(item => {
    return item.name == status[activeName];
  })?.value;
  const totalCount = statusCount?.planTotalCount;

  const percentage = value && totalCount && !isNaN(value) && !isNaN(totalCount) ? Math.round((value / totalCount) * 100) : 0;

  useEffect(() => {
    handleClick('PICKUP');
  }, []);

  const getMessage = status => {
    console.log('status' + status);
    switch (status) {
      case 'NOT_READY':
        return (
          <span>
            스케줄을 <span className="font-bold text-sky-500">등록 </span>했습니다.
          </span>
        );
      case 'READY':
        return (
          <span>
            배차를 <span className="font-bold text-sky-500">등록 </span>했습니다.
          </span>
        );
      case 'PICKUP_FAIL':
        return (
          <span>
            상차에 <span className="font-bold text-rose-500">실패 </span>했습니다.
          </span>
        );
      case 'DELIVERY_FAIL':
        return (
          <span>
            하차에 <span className="font-bold text-rose-500">실패 </span>했습니다.
          </span>
        );
      case 'SCHEDULE_CANCEL':
        return (
          <span>
            스케줄을 <span className="font-bold text-rose-500">취소 </span>했습니다.
          </span>
        );
      case 'CANCEL':
        return (
          <span>
            배차를 <span className="font-bold text-rose-500">취소 </span> 했습니다.
          </span>
        );
      default:
        return <span>상태를 확인할 수 없습니다. {status}</span>;
    }
  };

  return (
    <ContentWrapper>
      <div className="flex flex-col gap-6 p-4 mx-2 mb-2 bg-opacity-75 border-b border-default-100 rounded-medium">
        <div className="flex justify-between gap-4">
          <div className="flex items-center gap-4">
            {/* <div className="text-3xl font-bold text-zinc-600"> {date.format('YYYY년MM월DD일')}</div> */}
            <div className={`text-3xl font-bold  ${theme === 'light' ? 'text-zinc-600' : 'text-zinc-300'}`}> {formatDateToKoreanString(date)}</div>
            <Button isIconOnly size="sm" className="rounded-full default" onClick={prevDay}>
              {'<'}
            </Button>
            <Button isIconOnly size="sm" className="rounded-full dafault" onClick={nextDay}>
              {'>'}
            </Button>
            <Button size="sm" className="default" onClick={getToday}>
              오늘
            </Button>
          </div>

          <GroupSelect label="" selectedKey={params.pndGroupId} onChange={handleGroupChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
        </div>
      </div>
      <div>
        <div className="flex w-full ">
          <div className="w-2/3 px-2 py-2 min-w-[500px] h-[424px]">
            <Card className="w-full h-full ">
              <CardHeader className="flex gap-3">
                <DynamicIcon iconName={'Truck'} weight={'fill'} size={20} color="#75a6e6"></DynamicIcon>
                운송현황
              </CardHeader>
              <Divider />
              <CardBody className="overflow-hidden">
                <div className="flex justify-evenly">
                  <div className="flex items-center justify-center ">
                    <div>
                      <PieChart width={300} height={300}>
                        <Pie data={chartObj} cx={145} cy={155} innerRadius={80} outerRadius={100} fill="#8884d8" paddingAngle={2} dataKey="value">
                          {/* <Cell fill={COLORS[idx % COLORS.length]} /> */}
                          {chartObj.map(entry => (
                            <Cell key={`cell-${entry.idx}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                      <div className="relative bottom-40 left-32">
                        <div className="flex flex-col justify-center w-18">
                          <div className="text-2xl font-bold">{percentage}%</div>
                          <div className="text-sm text-zinc-400"> {status[activeName]} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="max-w-[500px] ">
                      <div className="font-bold text-1xl">{/* {notYetStartCnt} 건의 운송이 {chartPhrase} */}</div>
                      {/* <div className="flex justify-between mt-2">
                        <div className="flex items-center">
                          <span className="mx-2 text-zinc-600"> {'모든 스케줄'}</span>
                        </div>
                        <span>{statusCount?.planTotalCount} 건</span>
                      </div> */}
                      <div className="flex justify-between mt-2">
                        <div className="flex items-center">
                          <span>
                            <DynamicIcon iconName={'Circle'} size={10} color={'#CCCCCC'} weight="fill"></DynamicIcon>
                          </span>
                          <span className={`mx-2  ${theme === 'light' ? 'text-zinc-600' : 'text-zinc-300'} `}>{'예정 스케줄'}</span>
                        </div>
                        <span>{notYetStartCnt} 건</span>
                      </div>

                      {allObj?.map((item, idx) => {
                        return (
                          <div className="flex justify-between mt-2" key={idx}>
                            <div className="flex items-center">
                              <span>
                                <DynamicIcon iconName={'Circle'} size={10} color={item.color} weight="fill"></DynamicIcon>
                              </span>
                              <span className={`mx-2  ${theme === 'light' ? 'text-zinc-600' : 'text-zinc-300'} `}>{item.name}</span>
                            </div>
                            <span>{item.value} 건</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="w-1/3 px-2 py-2">
            <div className="flex flex-col gap-3">
              <Card key={'PICKUP'} className={`h-32 cursor-pointer hover:opacity-100 ${activeName == 'PICKUP' ? 'outline outline-2 outline-blue-500 opacity-100' : 'opacity-50 '} `}>
                <CardBody
                  className=""
                  onClick={() => {
                    handleClick('PICKUP');
                  }}
                >
                  <div className="flex items-center justify-start h-full">
                    <div className="p-1 mx-5 rounded-full bg-slate-100 ">
                      <DynamicIcon iconName={'ArrowFatLinesUp'} weight={'fill'} size={40} color="#75a6e6"></DynamicIcon>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-zinc-400">상차완료</div>
                      <div className="font-bold">
                        {data?.find(item => {
                          return item.name == '상차완료';
                        })?.value || 0}
                        건
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card key={'DELIVERY'} className={`h-32 cursor-pointer hover:opacity-100 ${activeName == 'DELIVERY' ? 'outline outline-2 outline-blue-500 opacity-100' : 'opacity-50 '}`}>
                <CardBody
                  className=""
                  onClick={() => {
                    handleClick('DELIVERY');
                  }}
                >
                  <div className="flex items-center justify-start h-full">
                    <div className="p-1 mx-5 rounded-full bg-slate-100 ">
                      <DynamicIcon iconName={'ArrowFatLinesDown'} weight={'fill'} size={40} color="#75a6e6"></DynamicIcon>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-zinc-400">하차완료</div>
                      <div className="font-bold">
                        {data?.find(item => {
                          return item.name == '하차완료';
                        })?.value || 0}
                        건
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card key={'COMPLETE'} className={`h-32 cursor-pointer hover:opacity-100 ${activeName == 'COMPLETE' ? 'outline outline-2 outline-blue-500 opacity-100' : 'opacity-50 '}`}>
                <CardBody
                  className=""
                  onClick={() => {
                    handleClick('COMPLETE');
                  }}
                >
                  <div className="flex items-center justify-start h-full">
                    <div className="p-1 mx-5 rounded-full bg-slate-100 ">
                      <DynamicIcon iconName={'Check'} size={40} color="#75a6e6"></DynamicIcon>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-zinc-400">구매확정</div>
                      <div className="font-bold">
                        {data?.find(item => {
                          return item.name == '구매확정';
                        })?.value || 0}
                        건
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>

        <div className="w-full px-2 mt-4">
          <Card>
            <CardBody>
              <CardHeader className="flex gap-3">
                <DynamicIcon iconName={'Bell'} weight={'fill'} size={20} color="#75a6e6"></DynamicIcon>
                운송･스케줄 알림
              </CardHeader>
              <Divider />
              <div className="max-h-[230px] overflow-auto">
                <Listbox className="mt-1">
                  {!dashboardAlarmPending &&
                    combinedAlarmList.map(item => {
                      return (
                        <ListboxItem key={item.id} className={`border-b divide-slate-200  ${theme === 'light' ? 'border-slate-200' : 'border-gray-500'}`}>
                          <div className={'flex items-center justify-between'}>
                            <div>
                              <CusChip color={statusColorName[item?.planStauts ? item?.planStauts : item?.planStatus]}>{status[item?.planStauts ? item?.planStauts : item?.planStatus]}</CusChip>
                              <span className="ml-2">
                                {item.driverName ? item.driverName : item.regName} 님께서 {'   '} {formatUtil.getKorMonthDate(formatUtil.getDate(item.planDate, '-'))}
                                {'   '}
                                {item.pickupPlaceName ? item.pickupPlaceName : item.fromPlaceName} {'->'}
                                {item.deliveryPlaceName ? item.deliveryPlaceName : item.toPlaceName}
                                {/* 스케줄을
                                {<span className="font-bold"> {status[item?.planStauts ? item?.planStauts : item?.planStatus]}</span>} 처리 하였습니다. */}
                                {<span> {getMessage(item?.planStauts ? item?.planStauts : item?.planStatus)}</span>}
                              </span>
                            </div>
                            <span className="text-zinc-400">{formatUtil.getDatetime(item.regDt)}</span>
                          </div>
                        </ListboxItem>
                      );
                    })}
                  {combinedAlarmList.length == 0 && <ListboxItem className="flex text-zinc-400">등록된 정보가 없습니다.</ListboxItem>}
                </Listbox>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </ContentWrapper>
  );
}

export default View;
