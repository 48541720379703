import React, { useContext, useEffect, useState } from 'react';
import PrivacyAndTermsModal from '@/components/Modal/PrivacyAndTermsModal';
import { Api, axiosInstance } from '@/api';
import { AuthContext } from '@/contexts/AuthContext';
import { colorUtil } from '@/utils/ColorUtil';
import { Button, Divider, Listbox, ListboxItem, ListboxSection, ScrollShadow, useDisclosure, User } from '@nextui-org/react';
import { FileMagnifyingGlass, Info, Moon, SidebarSimple, SignOut, Sun } from '@phosphor-icons/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import DynamicIcon from './DynamicIcon';
import { useTheme } from 'next-themes';
import toast, { Toaster } from 'react-hot-toast';

const Aside = () => {
  const navigate = useNavigate();
  axiosInstance.setNavigate(navigate);
  const { logout } = useContext(AuthContext);
  const { pathname } = useLocation();
  const { theme, setTheme } = useTheme();
  // Cookie state for sidebar
  const [cookies, setCookie] = useCookies(['sideBar']);
  const [isSidebarOpen, setIsSidebarOpen] = useState(cookies.sideBar === 'open');
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  // Query hooks
  const myinfoQuery = useQuery({
    queryKey: ['myinfo'],
    queryFn: Api.myinfo,
    select: res => res.data.data.admin,
    placeholderData: keepPreviousData,
  });

  const menuQuery = useQuery({
    queryKey: ['menu'],
    queryFn: Api.menu,
    select: res => res.data.data.menuMap,
    placeholderData: keepPreviousData,
  });

  // Handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setCookie('sideBar', !isSidebarOpen ? 'open' : 'closed', { path: '/' });
  };

  useEffect(() => {
    // Sync initial state with cookie
    setIsSidebarOpen(cookies.sideBar === 'open');
  }, [cookies.sideBar]);

  return (
    <>
      <PrivacyAndTermsModal isOpen={isOpen} onClose={onClose} onOpenChange={onOpenChange} />
      <div className={`group relative flex flex-col h-full p-6 border-r bg-default-50 border-default-200 ${isSidebarOpen ? 'min-w-72 max-w-72' : 'is-collapse w-20 px-3'}`}>
        <div className="flex items-center pb-8 group-[.is-collapse]:flex-wrap group-[.is-collapse]:justify-center group-[.is-collapse]:pb-6 gap-y-4 gap-x-2">
          <Link to="/" className="px-2 w-36 group-[.is-collapse]:hidden">
            <img className="block dark:hidden" src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/mango-light.svg`} alt="망고체크 로고" />
            <img className="hidden dark:block" src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/mango-dark.svg`} alt="망고체크 로고" />
          </Link>
          <Link to="/" className="h-7 w-7 group-[.is-collapse]:block hidden">
            <img src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/mango-symbol.svg`} alt="망고체크 로고" />
          </Link>
          <div className="mx-auto group-[.is-collapse]:hidden"></div>
          {theme === 'light' ? (
            <Button size="sm" isIconOnly variant="light" onClick={() => setTheme('dark')}>
              <Sun size={24} weight="duotone" className="text-default-400"></Sun>
            </Button>
          ) : (
            <Button size="sm" isIconOnly variant="light" onClick={() => setTheme('light')}>
              <Moon size={24} weight="duotone" className="text-default-400"></Moon>
            </Button>
          )}
          <Button size="sm" isIconOnly variant="light" onClick={toggleSidebar}>
            <SidebarSimple size={24} weight="duotone" className="text-default-400"></SidebarSimple>
          </Button>
        </div>
        <ScrollShadow className="flex-1">
          {menuQuery.data && (
            <motion.div
              className="space-y-2"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
            >
              <Listbox variant="flat" aria-label="Listbox menu with sections" selectedKeys={[pathname]} selectionMode="single">
                {menuQuery.data.parentMenuList.map(pMenu => (
                  <ListboxSection
                    key={pMenu.id}
                    classNames={{ group: 'group-[.is-collapse]:pt-2 group-[.is-collapse]:space-y-2', heading: 'group-[.is-collapse]:p-0', base: 'group-[.is-collapse]:mb-1' }}
                    title={isSidebarOpen ? pMenu.menuName : <Divider></Divider>}
                  >
                    {menuQuery.data.childMenuList.map(cMenu => {
                      const isSelected = pathname === cMenu.menuUrl;
                      return (
                        cMenu.parentAdminMenuId == pMenu.id && (
                          <ListboxItem
                            as={Link}
                            key={cMenu.menuUrl}
                            href={cMenu.menuUrl}
                            hideSelectedIcon
                            variant="flat"
                            classNames={{
                              base: 'px-3 h-11 rounded-large text-default-600  group-data-[selected=true]:text-foreground data-[selected=true]:!bg-default/50 group-[.is-collapse]:justify-center',
                              title: 'text-small font-medium text-default-600  group-data-[selected=true]:text-foreground group-[.is-collapse]:hidden',
                            }}
                            
                            startContent={<DynamicIcon size={20} weight={isSelected ? 'duotone' : 'regular'} iconName={cMenu.menuIconCode} />}
                          >
                            {cMenu.menuName}
                          </ListboxItem>
                        )
                      );
                    })}
                  </ListboxSection>
                ))}
              </Listbox>

              {/* <ListboxItem key={'0'} href={''}>
            <span className="font-medium text-small">{childMenuList.length + childMenuList.length}</span>
          </ListboxItem> */}
            </motion.div>
          )}
        </ScrollShadow>
        <div className="flex flex-col group-[.is-collapse]:items-center p-1 space-y-2">
          {myinfoQuery.data && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
            >
              <Button
                fullWidth
                isIconOnly={!isSidebarOpen}
                className="justify-start px-3 group-[.is-collapse]:px-2 "
                variant="light"
                size="lg"
                onClick={() => {
                  navigate(`/admin?pndGroupId=${myinfoQuery.data.pndGroupId}&searchType=loginId&searchText=${myinfoQuery.data.loginId}`);
                }}
              >
                <User
                  name={myinfoQuery.data.adminName}
                  description={myinfoQuery.data.loginId}
                  classNames={{
                    base: 'justify-center',
                    name: 'text-default-600 font-medium ',
                    description: 'text-default-500 ',
                  }}
                  avatarProps={{
                    name: myinfoQuery.data.adminName[0],
                    style: {
                      backgroundColor: colorUtil.hashStringToColor(myinfoQuery.data.adminName),
                      color: colorUtil.getContrastingColor(colorUtil.hashStringToColor(myinfoQuery.data.adminName)),
                    },
                    size: 'sm',
                  }}
                />
              </Button>
            </motion.div>
          )}
          <Button
            isIconOnly={!isSidebarOpen}
            fullWidth
            className="justify-start px-3 group-[.is-collapse]:px-0 group-[.is-collapse]:justify-center text-default-600"
            variant="light"
            startContent={<Info size={16} />}
            onPress={() => window.open('https://political-provelone-23e.notion.site/de36935d96e04370b39938e9c4bf1933', '_blank')}
          >
            <div className="group-[.is-collapse]:hidden">도움말 & 매뉴얼</div>
          </Button>
          <Button
            isIconOnly={!isSidebarOpen}
            fullWidth
            className="justify-start px-3 group-[.is-collapse]:px-0 group-[.is-collapse]:justify-center text-default-600"
            variant="light"
            startContent={<FileMagnifyingGlass size={16} />}
            onPress={onOpen}
          >
            <div className="group-[.is-collapse]:hidden">약관 및 정책</div>
          </Button>
          <Button
            isIconOnly={!isSidebarOpen}
            fullWidth
            className="justify-start px-3 group-[.is-collapse]:px-0 group-[.is-collapse]:justify-center"
            variant="light"
            color="danger"
            startContent={<SignOut size={16} />}
            onPress={logout}
          >
            <div className="group-[.is-collapse]:hidden">로그아웃</div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Aside;
