import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, getKeyValue } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

const ModalDirect = ({ isOpenDirect, onOpenChangeDirect, onCloseDirect, reloadList, selectedId, selectedJobId, selectedDeliveryName }) => {
  const columns = [
    { name: '기사', uid: 'name' },
    { name: '기사 전화번호', uid: 'tel' },
    { name: '차량번호', uid: 'vehicleNo' },
    { name: '차량구분', uid: 'type' },
    { name: '누적운임', uid: 'price', sortable: true },
    { name: '운반건수(건)', uid: 'count', sortable: true },
    { name: '하차지 ' + selectedDeliveryName + ' 방문횟수(건)', uid: 'deliveryCount', sortable: true },
  ];
  const [sortDescriptor, setSortDescriptor] = useState({
    column: 'price',
    direction: 'ascending',
  });

  const { callAlert } = useAlertModal();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const queryClient = useQueryClient();
  const [selectedKey, setSelectedKey] = useState(new Set([]));

  const { data: planDrivers, isLoading } = useQuery({
    queryKey: ['planDrivers', selectedJobId],
    queryFn: () => {
      return Api.planDrivers({ jobUnitId: selectedJobId, orderType: 10 });
    },
    select: res => res.data.data,
    enabled: !!selectedJobId,
  });
  const planDirect = useMutation({
    mutationFn: values => Api.planDirect(values),
  });
  const handleRegisterClick = () => {
    if (!selectedKey?.currentKey) {
      callAlert('배차할 기사를 선택하세요.');
      return;
    }
    const selectedItem = planDrivers?.driverList.find(item => {
      return String(item.id) === selectedKey?.currentKey;
    });

    let data = { id: selectedId, driverId: selectedItem.id, vehicleId: selectedItem.vehicleId };

    planDirect.mutate(data, {
      onSuccess: () => {
        callAlert('배차 되었습니다.', () => {
          reloadList();
          onCloseDirect();
        });
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('배차 실패하였습니다.');
        }
      },
    });
  };

  const { data: vehicleTypeMap } = useQuery({
    queryKey: ['codeMap', 'VEHICLE_TYPE'],
    queryFn: () => Api.codeMap({ cate: 'VEHICLE_TYPE' }),
    select: res => res.data.data,
  });

  const sortedItems = useMemo(() => {
    if (!planDrivers?.driverList) {
      return [];
    }

    let driverList = planDrivers?.driverList ?? [];
    return [...driverList].sort((a, b) => {
      let first = '';
      let second = '';
      if (sortDescriptor.column === 'price' || sortDescriptor.column === 'count') {
        a = a?.jobPlanDeliveryLastSum;
        b = b?.jobPlanDeliveryLastSum;
        first = a[sortDescriptor.column];
        second = b[sortDescriptor.column];
      }
      if (sortDescriptor.column === 'deliveryCount') {
        a = a?.jobPlanDeliveryLastSum?.jobPlanDeliveryLastCount;
        b = b?.jobPlanDeliveryLastSum?.jobPlanDeliveryLastCount;

        first = a['count'];
        second = b['count'];
      } else {
        first = a[sortDescriptor.column];
        second = b[sortDescriptor.column];
      }

      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [planDrivers?.driverList, sortDescriptor]);

  const renderCell = useCallback((item, columnKey) => {
    const jobPlanDeliveryLastSum = item.jobPlanDeliveryLastSum;
    switch (columnKey) {
      case 'tel':
        return <div>{formatUtil.getMobile(item.tel)}</div>;
      case 'price':
        return <div>{formatUtil.getNumber(jobPlanDeliveryLastSum.price)}</div>;
      case 'type':
        return <div>{vehicleTypeMap?.codeMap[item.vehicleType]}</div>;

      case 'count':
        return <div>{formatUtil.getNumber(jobPlanDeliveryLastSum.count)}</div>;
      case 'deliveryCount':
        return <div>{formatUtil.getNumber(jobPlanDeliveryLastSum?.jobPlanDeliveryLastCount.count)}</div>;
      default:
        return getKeyValue(item, columnKey);
    }
  }, []);

  useEffect(() => {
    setSelectedKey(new Set([]));
  }, [isOpenDirect]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenDirect} onOpenChange={onOpenChangeDirect} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <ModalHeader>직접 기사 배정</ModalHeader>
          <ModalBody>
            <div className={'text-zinc-400 text-sm'}>최근 30일 운송 현황 정보를 확인하고 배차하세요. 최근 30일 운송이 집계된 내역입니다. 기사는 1명만 배정 가능합니다.</div>
            <Table
              isHeaderSticky
              color={'primary'}
              aria-label="content"
              selectionMode="single"
              selectedKeys={selectedKey}
              onSelectionChange={setSelectedKey}
              sortDescriptor={sortDescriptor}
              onSortChange={setSortDescriptor}
            >
              <TableHeader columns={columns}>
                {column => (
                  <TableColumn key={column.uid} allowsSorting={column.sortable}>
                    {column.name}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody emptyContent={'등록된 정보가 없습니다.'} items={sortedItems} isLoading={isLoading} loadingContent={<Spinner></Spinner>}>
                {item => <TableRow key={item.id}>{columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}</TableRow>}
              </TableBody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="default" className="ml-auto" onPress={onCloseDirect}>
              닫기
            </Button>
            <Button
              color="primary"
              isDisabled={!planDrivers?.driverList}
              onPress={() =>
                requestConfirm({
                  message: '배차 하시겠습니까?',
                  onConfirm: handleRegisterClick,
                })
              }
            >
              배차하기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {planDirect.isPending && <LoadingBackground />}
      <AlertModal />
    </>
  );
};

export default ModalDirect;
