import { Pagination, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Button } from "@nextui-org/react";
import { rowsPerPage } from "@/constants/table";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { FileXls } from "@phosphor-icons/react";
// eslint-disable-next-line react/prop-types
const PaginationTable = ({
  data: { page, size, totalPages, totalElements, columns, items },
  renderCell,
  isLoading,
  isFetching,
  onRowsPerPageChange,
  onPageChange,
  onAddClick,
  onRowClick,
  onExelDownload,
  type,
  extrtaBtn1,
  extrtaBtn2,
  noAdd,
  selectedKeys,
  setSelectedKeys,
  params,
  setParams,
}) => {
  const [sortDescriptor, setSortDescriptor] = useState({});

  useEffect(() => {
    if (params?.orderType) {
      columns.forEach((column) => {
        if (column.sortable) {
          if (column.ascending === params.orderType) {
            setSortDescriptor({
              column: column.uid,
              direction: "ascending",
            });
          }
          if (column.descending === params.orderType) {
            setSortDescriptor({
              column: column.uid,
              direction: "descending",
            });
          }
        }
      });
    }
  }, []);

  const handleSortChange = useCallback(
    (item) => {
      const columnIndex = columns.findIndex((column) => column.uid === item.column);
      console.log(item, sortDescriptor, item.column !== sortDescriptor.column);

      if (!sortDescriptor.direction) {
        setParams((prevParams) => ({
          ...prevParams,
          orderType: columns[columnIndex].descending,
        }));
        setSortDescriptor({
          column: item.column,
          direction: "descending",
        });
        return;
      }

      if (item.column !== sortDescriptor.column) {
        setParams((prevParams) => ({
          ...prevParams,
          orderType: columns[columnIndex][sortDescriptor.direction],
        }));
        setSortDescriptor({
          column: item.column,
          direction: sortDescriptor.direction,
        });
        return;
      }

      const newOrderType = sortDescriptor.direction === "ascending" ? columns[columnIndex].descending : columns[columnIndex].ascending;
      setParams((prevParams) => ({
        ...prevParams,
        orderType: newOrderType,
      }));
      setSortDescriptor({
        column: item.column,
        direction: sortDescriptor.direction === "ascending" ? "descending" : "ascending",
      });
    },
    [columns, sortDescriptor, setParams]
  );

  const handleAddData = () => {
    onAddClick && onAddClick();
    // console.log('Add button clicked');
  };

  const handleRowClick = (item) => {
    onRowClick && onRowClick(item);
  };

  const handleExcelDownload = () => {
    onExelDownload && onExelDownload();
  };

  return (
    <div className="flex flex-col flex-1 space-y-2">
      <div className="flex items-center gap-2">
        <span className="text-sm text-foreground-500">총 {totalElements || 0}건</span>
        {isFetching && (
          <motion.div
            className="flex"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
          >
            <Spinner size="sm" color={"default"}></Spinner>
          </motion.div>
        )}
        <div className="mr-auto" />
        <div className="flex gap-2">
          {extrtaBtn1 && extrtaBtn1}
          {extrtaBtn2 && extrtaBtn2}
        </div>
        {onExelDownload && (
          <Button
            startContent={<FileXls></FileXls>}
            color="primary"
            onPress={() => {
              handleExcelDownload();
            }}
            variant="bordered"
          >
            엑셀파일 다운로드
          </Button>
        )}
        {!noAdd && (
          <Button
            color="primary"
            onPress={() => {
              handleAddData();
            }}
          >
            등록
          </Button>
        )}

        <Select
          aria-label="Rows per page"
          classNames={{
            trigger: "after:hidden border-none shadow-none !px-0 text-foreground-500 group-data-[has-value=true]:text-foreground-500",
            value: "text-right text-foreground-400",
            selectorIcon: "right-0",
          }}
          disallowEmptySelection
          variant={"underlined"}
          size="sm"
          handleAddData
          className="px-0 max-w-32"
          defaultSelectedKeys={[String(size)]}
          isClearable={false}
          onChange={onRowsPerPageChange}
          name="size"
          items={rowsPerPage}
        >
          {(item) => <SelectItem key={item.value}>{item.text}</SelectItem>}
        </Select>
      </div>
      <Table
        className="flex-1"
        removeWrapper
        selectionMode={type ? type : "single"}
        aria-label="table"
        onRowAction={() => {
          return;
        }}
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
        sortDescriptor={sortDescriptor}
        setSortDescriptor={setSortDescriptor}
        onSortChange={handleSortChange}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn
              style={{
                columnButton: {
                  width: "250px",
                },
              }}
              key={column.uid}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>

        <TableBody
          emptyContent={"등록된 정보가 없습니다."}
          isLoading={isLoading} //loadingContent={<Spinner></Spinner>}
          items={items || []}
        >
          {items?.map((item, idx) => (
            <TableRow key={item.id} onClick={() => handleRowClick(item)} classNames="cursor-pointer">
              {(columnKey) => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="flex justify-center">
        {items && (
          <Pagination
            aria-label="Page navigation"
            className="items-center justify-center"
            variant="light"
            showControls
            color="primary"
            page={page}
            total={totalPages || 1}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default PaginationTable;
