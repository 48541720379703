import { Api } from '@/api';
import { CheckboxGroup, Button, Input, Modal, ModalHeader, Tab, Tabs, ModalContent, ModalFooter, ModalBody, Select, SelectItem, useDisclosure, DatePicker, Checkbox } from '@nextui-org/react';
import { X } from '@phosphor-icons/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import useConfirmModal from '@/hooks/useConfirmModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useAlertModal, AlertModal } from '@/components/Modal/useAlertModal';
import { formatUtil } from '@/utils/FormatUtil';
import { iconSm } from '@/constants/size';
import { getLocalTimeZone, today } from '@internationalized/date';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';
import { PackageSelect } from '@/components/common/filter';

const ModalAdd = ({ isOpenAdd, onOpenChangeAdd, onCloseAdd, reloadList }) => {
  const { callAlert } = useAlertModal();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { isOpen: isOpenRepeat, onOpen: onOpenRepeat, onOpenChange: onOpenChangeRepeat, onClose: onCloseRepeat } = useDisclosure();

  let defaultDate = today(getLocalTimeZone());

  const [number, setNumber] = useState(null);
  const [unit, setUnit] = useState('일');
  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [endType, setEndType] = useState('count');
  const [count, setCount] = useState(null);
  const [modifyType, setModifyType] = useState('THIS');
  const [lastDate, setLastDate] = useState(false);

  const numberFocus = useRef();
  const countFocus = useRef();

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const addPlan = useMutation({
    mutationFn: values => Api.planAdd(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setTouched } = useFormik({
    initialValues: {
      groupId: '',
      planDate: defaultDate,
      jobUnitList: [],
      repeatYn: 'N',
      jobPlanPackageId: '',
      pickupPlaceId: '',
      deliveryPlaceId: '',
      planSort: '',
      price: '0',
      price2: '0',
      type: 'WASTE_COLLECT',
      jobRepeatPost: {},
      frequency: '',
      interval: 1,
      monthOfYear: '',
      weekOfMonth: '',
      dayOfWeek: '',
      dayOfMonth: '',
      endType: 'COUNT',
      endCount: '',
      endDate: defaultDate,
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required('운반회사를 선택해주세요.'),
      planDate: Yup.string().required('운송 일자를 선택해주세요.'),
      jobPlanPackageId: Yup.string().required('운송 물품을 선택해주세요.'),
      pickupPlaceId: Yup.string().required('상차지를 선택해주세요.'),
      deliveryPlaceId: Yup.string().required('하차지를 선택해주세요.'),
      type: Yup.string().required('유형을 선택해주세요.'),
      repeatYn: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      let data = {};
      data.groupId = values.groupId;
      data.planDate = String(values.planDate).replaceAll('-', '');
      data.jobUnitList = [
        {
          pickupPlaceId: values.pickupPlaceId,
          deliveryPlaceId: values.deliveryPlaceId,
          jobPlanPackageId: values.jobPlanPackageId,
          planSort: 1,
          price: values.price,
          price2: values.price2,
          type: values.type,
        },
      ];

      if (values.repeatYn === 'Y') {
        data.jobRepeatPost = {
          frequency: values.frequency,
          interval: values.interval,
          monthOfYear: values.monthOfYear,
          weekOfMonth: values.weekOfMonth,
          dayOfWeek: values.dayOfWeek,
          dayOfMonth: values.dayOfMonth,
          endType: values.endType,
        };
        if (values.endType === 'COUNT') {
          data.jobRepeatPost.endCount = values.endCount;
        } else {
          data.jobRepeatPost.endDate = String(values.endDate).replaceAll('-', '');
        }

        data.modifyType = modifyType.toUpperCase();
      } else {
        data.modifyType = 'THIS';
      }

      addPlan.mutate(data, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('등록되었습니다.', () => {
            onCloseAdd();
            reloadList();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('등록에 실패하였습니다.');
          }
        },
      });
    },
  });

  const { data: placeList } = useQuery({
    queryKey: ['placeList', values?.groupId],
    queryFn: () => Api.placeList({ pndGroupId: values?.groupId }),
    select: res => res.data.data,
    enabled: !!values?.groupId,
  });

  const { data: packageList } = useQuery({
    queryKey: ['packageList', values?.groupId],
    queryFn: () => Api.packages({ pndGroupId: values?.groupId }),
    select: res => res.data.data.jobPlanPackageList,
    enabled: !!values?.groupId,
  });

  useEffect(() => {
    if (values.managerTel) {
      const value = values.managerTel.replace(/\D/g, '');
      setFieldValue('managerTel', value);
    }
  }, [values.managerTel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      setTouched({ ...touched, ...errors });
      return;
    }
    if (values.repeatYn === 'Y') {
      if (!values.frequency) {
        callAlert('주기설정을 해주세요.');
        return;
      }
    }

    if (values.pickupPlaceId === values.deliveryPlaceId) {
      callAlert('상차지와 하차지가 같은 경우, 스케줄 등록이 불가합니다.');
      return;
    }
    requestConfirm({
      message: '등록 하시겠습니까?',
      onConfirm: handleSubmit,
    });
  };

  const handleRegisterRepeat = () => {
    const validation = () => {
      let result = true;
      if (!unit) {
        callAlert('주기를 선택해주세요. (일/주/월/년 단위)');
      }
      if (!number && !lastDate) {
        if (unit === '일') {
          callAlert('반복되는 일자를 입력하세요');
        } else if (unit === '주') {
          callAlert('반복되는 주 간격을 입력하세요');
        } else if (unit === '월') {
          callAlert('반복되는 날짜를 입력하세요');
        } else if (unit === '년') {
          callAlert('반복되는 횟수를 입력하세요');
        }
        numberFocus.current.focus();

        result = false;
      }

      if (unit === '주') {
        if (dayOfWeek.length == 0) {
          callAlert('반복되는 요일을 선택해주세요.');
          result = false;
        }
      }
      if (unit === '년') {
        if (!values.planDate) {
          callAlert('날짜를 선택해주세요.');
          result = false;
        }
      }

      if (endType === 'count') {
        if (!count) {
          callAlert('반복 종료횟수를 입력해주세요.');
          countFocus.current.focus();
          result = false;
        }
        if (count > 730) {
          callAlert('최대 반복횟수는 730회 입니다.');
          countFocus.current.focus();
          result = false;
        }
      }

      if (endType === 'date') {
        if (!values.endDate) {
          callAlert('반복 종료일을 선택해주세요.');
          result = false;
        }
      }

      return result;
    };

    if (validation()) {
      setFieldValue('frequency', unit);
      if (unit === '일' || unit === '주' || unit === '년') {
        setFieldValue('interval', number);
      }

      if (unit === '월') {
        if (lastDate) {
          setFieldValue('dayOfMonth', 'LAST');
        } else {
          setFieldValue('dayOfMonth', number);
        }
      }

      if (unit === '주') {
        setFieldValue('dayOfWeek', dayOfWeek.join());
      }

      if (endType === 'count') {
        setFieldValue('endType', 'COUNT');
        setFieldValue('endCount', count);
      } else if (endType === 'date') {
        setFieldValue('endType', 'DATE');
      }

      onCloseRepeat();
    }
  };

  const handleChangeLast = e => {
    setLastDate(e);
  };

  const handleDeleteRepeat = () => {
    setFieldValue('frequency', '');
    setFieldValue('interval', '');
    setFieldValue('dayOfMonth', '');

    setFieldValue('dayOfWeek', '');
    setFieldValue('endType', '');
    setFieldValue('endCount', '');
    setFieldValue('endDate', '');
    setNumber(null);
    setUnit('일');

    setDayOfWeek([]);
    setEndType('date');
    setCount(null);
    setEndType('count');
  };

  useEffect(() => {
    resetForm();
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpenAdd]);

  useEffect(() => {
    if (groupQuery.data?.groupList && isOpenAdd) {
      setFieldValue('groupId', String(groupQuery.data.groupList[0].id));
    }
  }, [groupQuery?.data, isOpenAdd]);

  useEffect(() => {
    //초기화
    setNumber(null);
    setUnit('일');
    setDayOfWeek([]);
    setEndType('date');
    setCount(null);
    setEndType('count');
  }, [isOpenRepeat]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>스케줄 등록</ModalHeader>
            <ModalBody>
              <FlexTable title={'기본정보'}>
                <FlexTableRow>
                  <FlexTableHeader>운반회사</FlexTableHeader>
                  <FlexTableData>
                    <Select name="groupId" aria-label="groupId" size="md" onChange={handleChange} disallowEmptySelection selectedKeys={[String(values.groupId)]}>
                      {groupQuery.data?.groupList?.map(item => (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>
                    일정유형<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <div className="flex items-end">
                      <Tabs
                        selectedKey={values.repeatYn}
                        onSelectionChange={e => {
                          if (e !== values.repeatYn) {
                            setFieldValue('repeatYn', e);
                          }
                        }}
                      >
                        <Tab key="N" title={'일회성'} />
                        <Tab key="Y" title={'반복'} />
                      </Tabs>
                      <div className="flex items-center h-10">
                        {values.repeatYn === 'Y' && (
                          <Button
                            color="primary"
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              onOpenRepeat();
                            }}
                          >
                            주기 설정
                          </Button>
                        )}
                      </div>
                    </div>
                    <div>
                      {values.repeatYn === 'Y' && values.frequency && (
                        <div className="flex items-center h-8 rounded-lg text-zinc-400 bg-slate-100 ">
                          <div className="flex ">
                            <span className="ml-2 ">반복 설정</span>
                            <span className="ml-2 text-zinc-800">
                              {values.frequency === '일' && <div>{values.interval === 'LAST' ? '마지막' : values.interval} 일 마다 실행</div>}
                              {values.frequency === '주' && (
                                <div>
                                  {values.interval} 주 마다 {values.dayOfWeek} 에 실행
                                </div>
                              )}
                              {values.frequency === '월' && <div>매월 {values.dayOfMonth === 'LAST' ? '마지막' : values.interval} 일 마다 실행</div>}

                              {values.frequency === '년' && (
                                <div>
                                  {formatUtil.getKorDate(String(values.planDate))}
                                  부터 {values.interval} 년 마다 실행
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="ml-6">반복 종료</span>
                            <span className="ml-2 text-zinc-800">
                              {values.endType === 'COUNT' && <div>{values.endCount} 회 반복 후 종료</div>}
                              {values.endType === 'DATE' && <div>{formatUtil.getKorDate(String(values.endDate))} 에 종료</div>}
                            </span>
                          </div>

                          <Button
                            color="#94a3b8"
                            size={iconSm}
                            className={'ml-auto'}
                            onClick={() => {
                              requestConfirm({
                                message: '반복일정을 삭제 하시겠습니까?',
                                onConfirm: handleDeleteRepeat,
                              });
                            }}
                          >
                            <X />
                          </Button>
                        </div>
                      )}
                    </div>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>
                    운송일자<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <DatePicker
                      startContent
                      size="md"
                      className={'max-w-[200px]'}
                      visibleMonths={1}
                      value={values.planDate}
                      onChange={e => {
                        if (e !== values.planDate) {
                          setFieldValue('planDate', e);
                        }
                      }}
                    />
                  </FlexTableData>
                </FlexTableRow>
              </FlexTable>
              <FlexTable title={'스케줄 정보'}>
                <FlexTableRow>
                  <FlexTableHeader>
                    운송유형<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <Tabs
                      selectedKey={values.type}
                      onSelectionChange={e => {
                        if (e !== values.type) {
                          setFieldValue('type', e);
                        }
                      }}
                    >
                      <Tab key={'WASTE_COLLECT'} title={'폐기물수거'} />
                      <Tab key={'CARGO_DELIVERY'} title={'화물운반'} />
                    </Tabs>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>
                    운송물품<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <Select
                      name="jobPlanPackageId"
                      aria-label="jobPlanPackageId"
                      size="md"
                      placeholder="선택해주세요"
                      onChange={handleChange}
                      disallowEmptySelection
                      isInvalid={touched.jobPlanPackageId && errors.jobPlanPackageId}
                      errorMessage={touched.jobPlanPackageId && errors.jobPlanPackageId}
                      selectedKeys={[String(values.jobPlanPackageId)]}
                    >
                      {packageList?.map(item => (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>
                    상차지<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <Select
                      name="pickupPlaceId"
                      aria-label="pickupPlaceId"
                      placeholder="선택해주세요"
                      size="md"
                      onChange={handleChange}
                      disallowEmptySelection
                      isInvalid={touched.pickupPlaceId && errors.deliveryPlaceId}
                      errorMessage={touched.pickupPlaceId && errors.pickupPlaceId}
                      selectedKeys={[String(values.pickupPlaceId)]}
                    >
                      {placeList?.placePage?.content.map(item => (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  </FlexTableData>
                  <FlexTableHeader>
                    하차지<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <Select
                      name="deliveryPlaceId"
                      aria-label="deliveryPlaceId"
                      placeholder="선택해주세요"
                      size="md"
                      onChange={handleChange}
                      disallowEmptySelection
                      isInvalid={touched.deliveryPlaceId && errors.deliveryPlaceId}
                      errorMessage={touched.deliveryPlaceId && errors.deliveryPlaceId}
                      selectedKeys={[String(values.deliveryPlaceId)]}
                    >
                      {placeList?.placePage?.content.map(item => (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>단가</FlexTableHeader>
                  <FlexTableData>
                    <div className="flex items-center justify-start gap-2">
                      <Input
                        name="price"
                        labelPlacement="outside"
                        placeholder="숫자만 입력 가능합니다."
                        pattern="\d*"
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('price', value);
                        }}
                        onBlur={handleBlur}
                        value={formatUtil.getNumber(values.price)}
                        isInvalid={touched.price && errors.price}
                        errorMessage={touched.price && errors.price}
                        maxLength={13}
                        endContent={'원'}
                      />
                    </div>
                  </FlexTableData>
                  <FlexTableHeader>기타 금액</FlexTableHeader>
                  <FlexTableData>
                    <div className="flex items-center justify-start gap-2">
                      <Input
                        name="price2"
                        labelPlacement="outside"
                        placeholder="숫자만 입력 가능합니다."
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('price2', Number(value));
                        }}
                        onBlur={handleBlur}
                        value={formatUtil.getNumber(values.price2)}
                        isInvalid={touched.price2 && errors.price2}
                        errorMessage={touched.price2 && errors.price2}
                        maxLength={13}
                        endContent={'원'}
                      />
                    </div>
                  </FlexTableData>
                  <FlexTableHeader>총 단가</FlexTableHeader>
                  <FlexTableData>
                    <div>{formatUtil.getNumber(Number(values.price || 0) + Number(values.price2 || 0))} 원</div>
                  </FlexTableData>
                </FlexTableRow>
              </FlexTable>
            </ModalBody>
            <ModalFooter>
              <Button color="default" className="ml-auto" onPress={onCloseAdd}>
                닫기
              </Button>
              <Button color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                확인
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />

      <Modal isDismissable={false} isOpen={isOpenRepeat} onOpenChange={onOpenChangeRepeat} size="xl">
        <ModalContent>
          {close => (
            <>
              <ModalHeader className="flex flex-col gap-1">반복 주기등록</ModalHeader>
              <ModalBody>
                <div className="flex flex-col items-start">
                  <div className="mt-4">다음주기로 반복</div>
                  <div className="flex items-center py-4">
                    <Input
                      name="number"
                      isDisabled={unit === '월' && lastDate}
                      labelPlacement="outside"
                      placeholder="횟수입력"
                      onChange={e => {
                        const value = e.target.value.replace(/[^\d]/g, '');
                        setNumber(value);
                      }}
                      onBlur={handleBlur}
                      ref={numberFocus}
                      value={number}
                      maxLength={3}
                      className="mr-4"
                    ></Input>
                    <Select
                      name="dayOfWeek"
                      aria-label="dayOfWeek"
                      size="md"
                      onChange={e => {
                        setUnit(e.target.value);
                      }}
                      disallowEmptySelection
                      selectedKeys={[unit]}
                    >
                      <SelectItem key={'일'}>일</SelectItem>
                      <SelectItem key={'주'}>주</SelectItem>
                      <SelectItem key={'월'}>월</SelectItem>
                      <SelectItem key={'년'}>년 </SelectItem>
                    </Select>
                    {unit === '월' && (
                      <div className="flex flex-col items-center w-full gap-4">
                        <Checkbox isSelected={lastDate} onValueChange={handleChangeLast}>
                          마지막 일
                        </Checkbox>
                      </div>
                    )}
                  </div>
                  {unit === '일' && (
                    <div>
                      <span>
                        <span className="font-bold">{number}</span> 일 마다 운송 스케줄을 자동으로 등록합니다.
                      </span>
                    </div>
                  )}
                  {unit === '주' && (
                    <div>
                      <div className="">다음 요일에 반복</div>
                      <div className="flex py-4">
                        <CheckboxGroup orientation="horizontal" value={dayOfWeek} onValueChange={setDayOfWeek}>
                          <Checkbox defaultSelected radius="full" value="월">
                            월
                          </Checkbox>
                          <Checkbox radius="full" className="ml-4" value="화">
                            화
                          </Checkbox>
                          <Checkbox radius="full" className="ml-4" value="수">
                            수
                          </Checkbox>
                          <Checkbox radius="full" className="ml-4" value="목">
                            목
                          </Checkbox>
                          <Checkbox radius="full" className="ml-4" value="금">
                            금
                          </Checkbox>
                          <Checkbox radius="full" className="ml-4" value="토">
                            토
                          </Checkbox>
                          <Checkbox radius="full" className="ml-4" value="일">
                            일
                          </Checkbox>
                        </CheckboxGroup>
                      </div>
                      <div>
                        <span>
                          <span className="font-bold">{number}</span> 주 마다 <span className="font-bold">{dayOfWeek.join()}</span> 에 운송 스케줄을 자동으로 등록합니다.
                        </span>
                      </div>
                    </div>
                  )}
                  {unit === '월' && (
                    <>
                      <div>
                        <span>
                          매월 <span className="font-bold">{lastDate ? '마지막' : number}</span> 일 마다 운송 스케줄을 자동으로 등록합니다.
                        </span>
                      </div>
                    </>
                  )}
                  {unit === '년' && (
                    <div>
                      <DatePicker
                        startContent
                        size="md"
                        className={'max-w-[200px] mb-4'}
                        visibleMonths={1}
                        value={values.planDate}
                        onChange={e => {
                          if (e !== values.planDate) {
                            setFieldValue('planDate', e);
                          }
                        }}
                      />
                      <div>
                        <span>
                          <span className="font-bold">{formatUtil.getKorDate(String(values.planDate))}</span>
                          부터 <span className="font-bold">{number}</span> 년 마다 운송 스케줄을 자동으로 등록합니다.
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="mt-10">반복 종료일 설정</div>
                  <div className="flex w-full gap-4 py-4">
                    <Select
                      name="endType"
                      className={'max-w-[100px]'}
                      aria-label="endType"
                      size="md"
                      onChange={e => {
                        setEndType(e.target.value);
                      }}
                      disallowEmptySelection
                      selectedKeys={[endType]}
                    >
                      <SelectItem key={'date'}>날짜</SelectItem>
                      <SelectItem key={'count'}>횟수</SelectItem>
                    </Select>
                    {endType === 'date' && (
                      <DatePicker
                        startContent
                        size="md"
                        className={'max-w-[200px]'}
                        visibleMonths={1}
                        value={values.endDate}
                        onChange={e => {
                          if (e !== values.endDate) {
                            setFieldValue('endDate', e);
                          }
                        }}
                      />
                    )}
                    {endType === 'count' && (
                      <Input
                        name="count"
                        ref={countFocus}
                        className={'max-w-[200px]'}
                        labelPlacement="count"
                        placeholder="횟수입력"
                        onChange={e => {
                          const value = e.target.value.replace(/[^\d]/g, '');
                          setCount(value);
                        }}
                        onBlur={handleBlur}
                        value={count}
                        maxLength={3}
                      ></Input>
                    )}
                  </div>
                  {endType === 'count' && (
                    <div>
                      <span>
                        <span className="font-bold">{count}</span> 회 반복 후 종료합니다.
                      </span>
                    </div>
                  )}
                  {endType === 'date' && (
                    <div>
                      <span>
                        <span className="font-bold">{formatUtil.getKorDate(String(values.endDate))}</span> 종료합니다.
                      </span>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseRepeat}>
                  닫기
                </Button>
                <Button color="primary" disabled={isSubmitting} onPress={handleRegisterRepeat}>
                  확인
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAdd;
