import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import CustomDateRangePicker from '@/components/CustomDateRagePicker';
import LoadingBackground from '@/components/LoadingBackground';
import { useAlertModal } from '@/components/Modal/useAlertModal';
import PaginationTable from '@/components/PaginationTable';
import { GroupSelect, PackageSelect, PlaceSelect } from '@/components/common/filter';
import { status, statusColor, statusIcon } from '@/constants/planStatus';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import useDebounce from '@/hooks/useDebounce';
import { ExcelDownloadUtil } from '@/utils/ExcelDownloadUtil';
import { formatUtil } from '@/utils/FormatUtil';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { Button, Chip, Input, Select, SelectItem, Spinner, getKeyValue, useDisclosure } from '@nextui-org/react';
import { ArrowsClockwise, Lightning } from '@phosphor-icons/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DynamicIcon from '../../components/DynamicIcon';
import ModalDirect from './ModalDirect';
import ModalSmart from './ModalSmart';
import ModalView from './ModalView';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '스케줄번호', uid: 'jobUnitId' },
  { name: '배차상태', uid: 'planStatus' },
  { name: '상차지', uid: 'pickupPlaceName' },
  { name: '하차지', uid: 'deliveryPlaceName' },
  { name: '일정 유형', uid: 'jobRepeatId' },
  { name: '운송유형', uid: 'type' },
  { name: '운송물품', uid: 'jobPlanPackageName' },
  { name: '요일', uid: 'dayOfWeek' },
  { name: '차량번호', uid: 'vehicleNo' },
  { name: '기사명', uid: 'driverName' },
  { name: '총 단가(원)', uid: 'price' },
  { name: '운송일자', uid: 'planDate' },
];

const columnWidths = [50, 50, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100];

const excelCol = [
  { v: 'No', t: 's' },
  { v: '스케줄번호', t: 's' },
  { v: '배차상태', t: 's' },
  { v: '상차지', t: 's' },
  { v: '하차지', t: 's' },
  { v: '일정 유형', t: 's' },
  { v: '운송유형', t: 's' },
  { v: '운송물품', t: 's' },
  { v: '요일', t: 's' },
  { v: '차량번호', t: 's' },
  { v: '기사명', t: 's' },
  { v: '총 단가(원)', t: 's', s: { alignment: { horizontal: 'right' } } },
  { v: '운송일자', t: 's' },
];

const type = {
  CARGO_DELIVERY: '화물운반',
  WASTE_COLLECT: '폐기물수거',
};

const planDay = [
  { value: '', label: '전체' },
  { value: '월', label: '월' },
  { value: '화', label: '화' },
  { value: '수', label: '수' },
  { value: '목', label: '목' },
  { value: '금', label: '금' },
  { value: '토', label: '토' },
  { value: '일', label: '일' },
];
const searchType = [
  { value: 'pickupPlaceNameLike', label: '상차지' },
  { value: 'deliveryPlaceNameLike', label: '하차지' },
  { value: 'driverNameLike', label: '기사이름' },
  { value: 'vehicleNoLike', label: '차량번호' },
];

const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenSmart, onOpen: onOpenSmart, onOpenChange: onOpenChangeSmart, onClose: onCloseSmart } = useDisclosure();
  const { isOpen: isOpenDirect, onOpen: onOpenDirect, onOpenChange: onOpenChangeDirect, onClose: onCloseDirect } = useDisclosure();

  const { callAlert } = useAlertModal();
  let defaultDate = today(getLocalTimeZone());
  defaultDate = defaultDate.add({ months: 0 });
  const month1 = defaultDate.add({ months: 1 });
  const selectedId = useRef('');
  const queryClient = useQueryClient();
  const [selectedKey, setSelectedKey] = useState(new Set([]));
  const [searchParams] = useSearchParams();

  const selectedJobId = useRef('');
  const selectedDeliveryName = useRef('');

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    planStatus: searchParams.get('planStatus') || '',
    pickupPlaceId: searchParams.get('pickupPlaceId') || '',
    deliveryPlaceId: searchParams.get('deliveryPlaceId') || '',
    jobPlanPackageId: searchParams.get('jobPlanPackageId') || '',
    type: searchParams.get('type') || '',
    repeatYn: searchParams.get('repeatYn') || '',
    planDay: searchParams.get('planDay') || '',
    dateStart: searchParams.get('dateStart') || String(defaultDate).replaceAll('-', ''),
    dateEnd: searchParams.get('dateEnd') || String(month1).replaceAll('-', ''),
    searchType: searchParams.get('searchType') || 'pickupPlaceNameLike',
    searchText: searchParams.get('searchText') || '',
    like: searchParams.get('like') || '',
  };

  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);
  const [focusedValue, setFocusedValue] = useState(parseDate(formatUtil.getDate(params.dateStart, '-')));

  const debounce = useDebounce(params.searchText, 500);

  const [textLoading, setTextLoading] = useState(false);

  useEffect(() => {
    if (debounce != params.searchText) {
      setTextLoading(true);
    } else {
      setTextLoading(false);
    }
  }, [debounce, params.searchText]);

  const getParams = (pageNo, pageSize) => {
    let postParams = { pageNo: pageNo, pageSize: pageSize };
    postParams.pndGroupId = params.pndGroupId;
    postParams.planStatus = params.planStatus;
    postParams.pickupPlaceId = params.pickupPlaceId;
    postParams.deliveryPlaceId = params.deliveryPlaceId;
    postParams.jobPlanPackageId = params.jobPlanPackageId;
    postParams.repeatYn = params.repeatYn;
    postParams.type = params.type;
    postParams.planDay = params.planDay;
    postParams.dateStart = params.dateStart;
    postParams.dateEnd = params.dateEnd;
    postParams.like = params.like;
    postParams.planStatusNotList = 'SCHEDULE_CANCEL, PICKUP_FAIL, DELIVER_FAIL, FAIL, COMPLETE, REQUEST';
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    return Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
  };

  const fetchPlanList = () => {
    const trimParams = getParams(params.pageNo, params.pageSize);
    return Api.planList(trimParams);
  };

  const [sortDescriptor, setSortDescriptor] = useState({
    column: 'price',
    direction: 'ascending',
  });

  const reloadList = () => {
    queryClient.invalidateQueries(['planList', { ...queryKey, searchText: debounce }]);
  };

  const {
    data: planList,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['planList', { ...queryKey, searchText: debounce }],
    queryFn: fetchPlanList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
    enabled: !!params.pndGroupId,
  });

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      const jobUnit = item.jobUnitList.length > 0 && item.jobUnitList[0];
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(planList?.jobPlanPage?.totalElements ? planList?.jobPlanPage?.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'jobUnitId': {
          return <div>{jobUnit.id}</div>;
        }
        case 'jobPlanPackageName':
          if (jobUnit?.jobPlanPackageName) {
            return (
              <Chip size="sm" variant="flat" className="text-default-500">
                {jobUnit.jobPlanPackageName}
              </Chip>
            );
          } else {
            return '-';
          }
        case 'planStatus':
          return (
            <div className={`flex items-center gap-1 text-sm`}>
              <DynamicIcon className={statusColor[item.planStatus]} iconName={statusIcon[item.planStatus]} weight={'fill'}></DynamicIcon>
              <span className={statusColor[item.planStatus]}>{status[item.planStatus]}</span>
            </div>
          );

        case 'pickupPlaceName':
          return <div>{jobUnit.pickupPlaceName}</div>;

        case 'deliveryPlaceName':
          return <div>{jobUnit.deliveryPlaceName}</div>;

        case 'jobRepeatId':
          return (
            <div>
              {item.jobRepeatId ? (
                <div className="flex items-center gap-1 font-medium text-success-500">
                  <ArrowsClockwise weight="fill"></ArrowsClockwise>
                  <span>반복</span>
                </div>
              ) : (
                <div className="flex items-center gap-1 font-medium text-zinc-400">
                  <Lightning weight="fill" />
                  <span>일회성</span>
                </div>
              )}
            </div>
          );

        case 'dayOfWeek':
          return <div>{formatUtil.getDayOfWeek(item.planDate.substring(0, 8))}</div>;

        case 'type':
          return (
            <Chip size="sm" variant="flat" className="text-default-500">
              {type[jobUnit.type]}
            </Chip>
          );

        case 'price':
          return <div>{formatUtil.getNumber(Number(jobUnit.price || 0) + Number(jobUnit.price2 || 0))}</div>;
        case 'planDate':
          return <div>{formatUtil.getDate(item.planDate, '-')}</div>;
        case 'vehicleNo':
          return <div>{item.vehicleNo ? item.vehicleNo : '-'}</div>;
        case 'driverName':
          return <div>{item.driverName ? formatUtil.getMaskName(item.driverName) : '-'}</div>;
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [planList?.jobPlanPage?.totalElements, params.pageNo]
  );

  const handleOpenSmartModal = () => {
    if (selectedKey.size < 1) {
      callAlert('스케줄을 먼저 선택하세요.');
      return;
    }
    onOpenSmart();
  };

  const handleOpenDirectModal = () => {
    if (selectedKey.size < 1) {
      callAlert('배차할 스케줄을 먼저 선택하세요.');
      return;
    }
    if (selectedKey.size > 1 || selectedKey === 'all') {
      callAlert('배차할 항목은 하나만 선택하세요.');
      return;
    }

    const jobUnit = planList?.jobPlanPage.content.find(item => {
      return selectedKey.currentKey === String(item.id);
    });

    if (jobUnit.planStatus === 'READY') {
      callAlert('이미 배차가 완료된 스케줄입니다.');
      return;
    }

    selectedId.current = selectedKey.currentKey;
    selectedJobId.current = jobUnit?.jobUnitList[0].id;
    selectedDeliveryName.current = jobUnit?.jobUnitList[0].deliveryPlaceName;
    onOpenDirect();
  };

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const fetchPlanListForExcel = () => {
    const trimParams = getParams(params.pageNo, 1000000);
    return Api.planList(trimParams);
  };

  const excelDownload = useCallback(excelData => {
    const formattedData = excelData.map((data, idx) => {
      const jobUnit = data.jobUnitList.length > 0 && data.jobUnitList[0];
      return [
        status[data.planStatus],
        jobUnit.id,
        jobUnit.pickupPlaceName,
        jobUnit.deliveryPlaceName,
        data.jobRepeatId ? '반복' : '일회성',
        type[jobUnit.type],
        jobUnit.jobPlanPackageName,
        formatUtil.getDayOfWeek(data.planDate.substring(0, 8)),
        data.vehicleNo,
        data.driverName,
        formatUtil.getNumber(Number(jobUnit.price || 0) + Number(jobUnit.price2 || 0)),
        formatUtil.getDate(data.planDate, '-'),
      ];
    });

    ExcelDownloadUtil(formattedData, '배차목록', '배차목록', excelCol, columnWidths);
  }, []);

  const handleExcelDownload = async () => {
    try {
      const result = await fetchPlanListForExcel();
      if (result.data.code === 'SUCC') {
        excelDownload(result.data.data.jobPlanPage.content);
      }
    } catch (error) {
      console.error('Excel download failed', error);
    }
  };

  //운반회사 최초로딩시
  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  const handlePageChange = newPage => {
    setParams(prevParams => ({
      ...prevParams,
      pageNo: newPage,
    }));
    setSelectedKey(new Set([]));
  };

  const handleRowsPerPageChange = e => {
    setParams(prevParams => ({
      ...prevParams,
      pageSize: parseInt(e.target.value),
      pageNo: 1,
    }));
    setSelectedKey(new Set([]));
  };

  useEffect(() => {
    setSelectedKey(new Set([]));
  }, [isOpenView, planList]);

  return (
    <ContentWrapper>
      {isFetching && <LoadingBackground />}
      <ModalDirect
        isOpenDirect={isOpenDirect}
        onOpenChangeDirect={onOpenChangeDirect}
        onCloseDirect={onCloseDirect}
        reloadList={reloadList}
        selectedJobId={selectedJobId.current}
        selectedId={selectedId.current}
        selectedDeliveryName={selectedDeliveryName.current}
      ></ModalDirect>

      <ModalView isOpenView={isOpenView} onOpenChangeView={onOpenChangeView} onCloseView={onCloseView} selectedId={selectedId.current} reloadList={reloadList} onOpenView={onOpenView}></ModalView>

      <ModalSmart
        isOpenSmart={isOpenSmart}
        onOpenChangeSmart={onOpenChangeSmart}
        selectedId={selectedId.current}
        reloadList={reloadList}
        onOpenSmart={onOpenSmart}
        onCloseSmart={onCloseSmart}
        selectedKey={
          selectedKey == 'all'
            ? planList?.jobPlanPage.content.map(item => {
                return item.id;
              })
            : selectedKey
        }
      ></ModalSmart>

      {/* <div className="py-2 text-xl">배차관리</div> */}
      <div className="flex flex-col gap-4 p-4 mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 rounded-medium">
        <div className="flex gap-4">
          <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
          <Select
            size="sm"
            labelPlacement="outside"
            placeholder=" "
            disallowEmptySelection
            label="배차상태"
            className="max-w-48"
            selectedKeys={[params.planStatus]}
            onChange={onInputChange}
            name="planStatus"
          >
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="READY">배차완료</SelectItem>
            <SelectItem key="NOT_READY">미정</SelectItem>
          </Select>

          <PlaceSelect label="상차지" selectedKey={params.pickupPlaceId} onChange={onInputChange} name="pickupPlaceId" groupId={params.pndGroupId} />
          <PlaceSelect label="하차지" selectedKey={params.deliveryPlaceId} onChange={onInputChange} name="deliveryPlaceId" groupId={params.pndGroupId} />
        </div>
        <div className="flex gap-4">
          <Select
            size="sm"
            labelPlacement="outside"
            label="일정유형"
            placeholder=" "
            disallowEmptySelection
            className="max-w-48"
            selectedKeys={[params.repeatYn]}
            onChange={onInputChange}
            name="repeatYn"
          >
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="Y">반복</SelectItem>
            <SelectItem key="N">일회성</SelectItem>
          </Select>

          <Select size="sm" labelPlacement="outside" placeholder=" " disallowEmptySelection label="운송유형" className="max-w-48" selectedKeys={[params.type]} onChange={onInputChange} name="type">
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="WASTE_COLLECT">폐기물수거</SelectItem>
            <SelectItem key="CARGO_DELIVERY">화물운반</SelectItem>
          </Select>
          <Select
            size="sm"
            labelPlacement="outside"
            placeholder=" "
            label="요일"
            disallowEmptySelection
            className="max-w-48"
            selectedKeys={[params.planDay]}
            items={planDay}
            onChange={onInputChange}
            name="planDay"
          >
            {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
          </Select>
          <PackageSelect groupId={params.pndGroupId} name="jobPlanPackageId" selectedKey={String(params.jobPlanPackageId)} onChange={onInputChange}></PackageSelect>
        </div>
        <div className="flex-gap-4">
          <CustomDateRangePicker
            setParams={setParams}
            label="운송일자"
            value={{
              start: parseDate(formatUtil.getDate(params.dateStart, '-')),
              end: parseDate(formatUtil.getDate(params.dateEnd, '-')),
            }}
          />
        </div>
        <div className="flex gap-4">
          <Input
            labelPlacement="outside"
            label="검색"
            startContent={
              <Select
                disallowEmptySelection
                className="w-32 shrink-0"
                classNames={{
                  base: 'h-full items-center flex-row -mx-2',
                  trigger: '!bg-transparent !shadow-none h-full min-h-min',
                  mainWrapper: 'h-full',
                }}
                selectedKeys={[params.searchType]}
                items={searchType}
                onChange={onInputChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
            }
            size="sm"
            className="w-full"
            placeholder="검색어를 입력하세요"
            onChange={onInputChange}
            value={params.searchText}
            name="searchText"
            endContent={textLoading && <Spinner size="sm" color="primary" />}
          />
        </div>
      </div>

      <PaginationTable
        type="multiple"
        //disabledBehavior="selection"
        data={{
          page: params.pageNo,
          size: params.pageSize,
          totalPages: planList?.jobPlanPage.totalPages,
          totalElements: planList?.jobPlanPage.totalElements,
          columns: columns,
          items: planList?.jobPlanPage.content,
        }}
        renderCell={renderCell}
        isFetching={isFetching}
        isLoading={isLoading}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onRowClick={handlerRowClick}
        onExelDownload={handleExcelDownload}
        extrtaBtn1={
          <Button color="primary" variant="bordered" onClick={handleOpenDirectModal}>
            직접기사 배정하기
          </Button>
        }
        extrtaBtn2={
          <Button color="primary" onClick={handleOpenSmartModal}>
            스마트 AI 배차
          </Button>
        }
        noAdd={true}
        selectedKeys={selectedKey}
        setSelectedKeys={setSelectedKey}
      />
    </ContentWrapper>
  );
};

export default List;
