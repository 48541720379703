import Aside from '@/components/Aside';
import MenuContext from '@/contexts/MenuContext';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const MainLayout = () => {
  const { pathname } = useLocation();
  const { childMenuList } = useContext(MenuContext);
  const menu = childMenuList.find(item => {
    return item.menuUrl === pathname;
  });
  return (
    <>
      <Aside></Aside>
      <div className="flex flex-col px-12 py-6 overflow-y-scroll grow">
        <div className="pb-6">
          <h1 className="text-2xl font-semibold">{menu?.menuName}</h1>
          <h1 className="text-sm text-default-500">{menu?.jobType === 'MENU_SUB' ? menu?.menuName : ''}</h1>
        </div>
        <Outlet></Outlet>
      </div>
    </>
  );
};

export default MainLayout;
