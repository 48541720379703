import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import LoadingBackground from '@/components/LoadingBackground';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import PaginationTable from '@/components/PaginationTable';
import { GroupSelect } from '@/components/common/filter';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import useDebounce from '@/hooks/useDebounce';
import { ExcelDownloadUtil } from '@/utils/ExcelDownloadUtil';
import { formatUtil } from '@/utils/FormatUtil';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { Input, Select, SelectItem, Spinner, getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalView from './ModalView';
import { status } from '@/constants/planStatus';
import CustomDateRangePicker from '@/components/CustomDateRagePicker';
import ModalAdd from './ModalAdd';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '차주', uid: 'vehicleOwner' },
  { name: '차량번호', uid: 'vehicleNoList' },
  { name: '합계(원)', uid: 'totalPrice' },
  { name: '운송기간', uid: 'period' },
  { name: '생성일', uid: 'regDate' },
  { name: '작성자', uid: 'regName' },
];

const columnWidths = [50, 100, 100, 100, 100, 100, 100];

const excelCol = [
  { v: 'No', t: 's' },
  { v: '차주', t: 's' },
  { v: '차량번호', t: 's' },
  { v: '합계(원)', t: 's' },
  { v: '운송기간', t: 's' },
  { v: '생성일', t: 's' },
  { v: '작성자', t: 's' },
];

const searchTypeOptions = [
  { value: 'vehicleOwnerLike', label: '차주명' },
  { value: 'vehicleNoLike', label: '차량번호' },
];

const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();

  const { callAlert } = useAlertModal();
  const defaultDate = today(getLocalTimeZone());
  const beforeOneMonth = defaultDate.add({ months: -1 });
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState('');

  const handlerAddClick = () => {
    onOpenAdd();
  };

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    regDateStart: searchParams.get('regDateStart') || String(beforeOneMonth).replaceAll('-', ''),
    regDateEnd: searchParams.get('regDateEnd') || String(defaultDate).replaceAll('-', ''),
    searchType: searchParams.get('searchType') || 'vehicleOwnerLike',
    searchText: searchParams.get('searchText') || '',
  };

  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const debounceSearchText = useDebounce(params.searchText, 500);
  const [textLoading, setTextLoading] = useState(false);

  useEffect(() => {
    setTextLoading(debounceSearchText !== params.searchText);
  }, [debounceSearchText, params.searchText]);

  const reloadList = () => {
    queryClient.invalidateQueries(['jobCostList', { ...queryKey, searchText: debounceSearchText }]);
  };
  const getParams = (pageNo, pageSize) => {
    const postParams = {
      pageNo,
      pageSize,
      pndGroupId: params.pndGroupId,
      regDateStart: params.regDateStart,
      regDateEnd: params.regDateEnd,
      ...(params.searchText && { [params.searchType]: params.searchText }),
    };
    return Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
  };
  const {
    data: jobCostList,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['jobCostList', { ...queryKey, searchText: debounceSearchText }],
    queryFn: () => {
      return Api.jobCostList(getParams(params.page, params.pageSize));
    },
    placeholderData: keepPreviousData,
    enabled: !!params.pndGroupId,
    select: res => res.data.data,
  });

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no': {
          const totalElements = jobCostList?.jobCostPage?.totalElements || 0;
          const rowNumber = totalElements - ((params.pageNo - 1) * params.pageSize + idx);
          return <div>{rowNumber}</div>;
        }
        case 'vehicleNoList':
          return (
            <div>
              {item.vehicleNoList?.length > 0
                ? `${item.vehicleNoList
                    .slice(0, 2)
                    .map(el => el.vehicleNo)
                    .join(', ')}${item.vehicleNoList.length > 2 ? ` 외 ${item.vehicleNoList.length - 2}건` : ''}`
                : '-'}
            </div>
          );

        case 'period':
          return (
            <div>
              {formatUtil.getDate(item.startDate, '-')} ~ {formatUtil.getDate(item.endDate, '-')}
            </div>
          );
        case 'regDate':
          return <div>{formatUtil.getDate(item.regDate, '-')}</div>;
        default:
          return <div>{getKeyValue(item, columnKey) || '-'}</div>;
      }
    },
    [jobCostList?.jobCostPage?.totalElements, params.pageNo, params.pageSize]
  );

  const handleRowClick = item => {
    onOpenView();
    setSelectedId(item.id);
  };

  const fetchJobCostListForExcel = () => {
    const trimParams = getParams(params.pageNo, 1000000);
    return Api.jobCostList(trimParams);
  };

  const excelDownload = useCallback(excelData => {
    const formattedData = excelData.map(data => {
      const vehicleNoListStr = data.vehicleNoList?.length > 0 ? `${data.vehicleNoList.map(el => el.vehicleNo).join(', ')}` : '-';
      const period = `${formatUtil.getDate(data.startDate, '-')} ~ ${formatUtil.getDate(data.endDate, '-')}`;
      return [data.vehicleOwner || '-', vehicleNoListStr, data.totalPrice || 0, period, formatUtil.getDate(data.regDate, '-'), data.regName || '-'];
    });

    ExcelDownloadUtil(formattedData, '운송비정산', '운송비정산', excelCol, columnWidths);
  }, []);

  const handleExcelDownload = async () => {
    try {
      const result = await fetchJobCostListForExcel();
      if (result.data.code === 'SUCC') {
        excelDownload(result.data.data.jobCostPage.content);
      }
    } catch (error) {
      console.error('Excel download failed', error);
    }
  };

  // 운반회사 최초 로딩 시
  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  return (
    <ContentWrapper>
      {isFetching && <LoadingBackground />}
      <ModalView isOpenView={isOpenView} onOpenChangeView={onOpenChangeView} onCloseView={onCloseView} selectedId={selectedId} reloadList={reloadList} onOpenView={onOpenView} />
      <ModalAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadList} params={params} setParams={setParams}></ModalAdd>

      <div className="flex flex-col gap-4 p-4 mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 rounded-medium">
        <div className="flex gap-4">
          <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
          <CustomDateRangePicker
            setParams={setParams}
            size="sm"
            className="max-w-xs"
            label="생성일"
            dateKey={{
              start: 'regDateStart',
              end: 'regDateEnd',
            }}
            value={{
              start: parseDate(formatUtil.getDate(params.regDateStart, '-')),
              end: parseDate(formatUtil.getDate(params.regDateEnd, '-')),
            }}
          />
        </div>

        <div className="flex gap-4">
          <Input
            labelPlacement="outside"
            label="검색"
            size="sm"
            className="w-full"
            placeholder="검색어를 입력하세요"
            onChange={onInputChange}
            value={params.searchText}
            name="searchText"
            startContent={
              <Select
                disallowEmptySelection
                className="w-32 shrink-0"
                classNames={{
                  base: 'h-full items-center flex-row -mx-2',
                  trigger: '!bg-transparent !shadow-none h-full min-h-min',
                  mainWrapper: 'h-full',
                }}
                selectedKeys={[params.searchType]}
                items={searchTypeOptions}
                onChange={onInputChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
            }
            endContent={textLoading && <Spinner size="sm" color="primary" />}
          />
        </div>
      </div>
      {jobCostList?.isLoading ? (
        <div className="flex items-center justify-center h-96">
          <Spinner />
        </div>
      ) : (
        <PaginationTable
          type="single"
          data={{
            page: params.pageNo,
            size: params.pageSize,
            totalPages: jobCostList?.jobCostPage?.totalPages,
            totalElements: jobCostList?.jobCostPage?.totalElements,
            columns: columns,
            items: jobCostList?.jobCostPage?.content,
          }}
          params={params}
          setParams={setParams}
          renderCell={renderCell}
          isFetching={isFetching}
          isLoading={isLoading}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onRowClick={handleRowClick}
          onExelDownload={handleExcelDownload}
          onAddClick={handlerAddClick}
        />
      )}
      <AlertModal />
    </ContentWrapper>
  );
};

export default List;
