import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import { iconSm } from '@/constants/size';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Radio, RadioGroup, useDisclosure } from '@nextui-org/react';
import { ArrowsClockwise, Lightning } from '@phosphor-icons/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { status } from '@/constants/planStatus';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const type = {
  CARGO_DELIVERY: '화물운반',
  WASTE_COLLECT: '폐기물수거',
};
const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, onOpenUpdate }) => {
  const queryClient = useQueryClient();
  const { callAlert } = useAlertModal();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onOpenChange: onOpenChangeConfirm, onClose: onCloseConfirm } = useDisclosure();

  const {
    data: planQuery,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: ['plan', selectedId],
    queryFn: () => Api.planView({ jobPlanId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenView,
  });

  const [deleteType, setDeleteType] = useState('THIS');

  let plan = planQuery?.jobPlan;
  let jobUnit = planQuery?.jobPlan?.jobUnitList.length > 0 && planQuery?.jobPlan?.jobUnitList[0];
  let jobRepeat = planQuery?.jobPlan?.jobRepeat;

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const reloadView = () => {
    queryClient.invalidateQueries(['plan', selectedId]);
  };

  const delPlan = useMutation({
    mutationFn: () => Api.planDel({ jobPlanId: selectedId, deleteType: deleteType }),
    onSuccess: () => {
      onCloseConfirm();
      onCloseView();
      callAlert('처리되었습니다.', () => {
        reloadList();
      });
    },
    onError: error => {
      if (error.response.data.code == 'FAIL' && error.response.data.msg) {
        callAlert(error.response.data.msg);
      }
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = () => {
    delPlan.mutate();
  };

  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
          <ModalContent>
            {close => (
              <>
                <ModalHeader>스케줄 정보</ModalHeader>
                <ModalBody>
                  <FlexTable title={'기본정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>등록일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.regDt, '-')}</FlexTableData>
                      <FlexTableHeader>수정일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.modDt, '-')}</FlexTableData>
                      <FlexTableHeader>작성자</FlexTableHeader>
                      <FlexTableData>{plan?.regName}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>일정유형 </FlexTableHeader>
                      <FlexTableData>
                        {jobRepeat ? (
                          <div className="flex items-center gap-2 text-sm align-middle text-zinc-400 size-30">
                            <div className="flex items-center gap-1 font-medium text-success-500">
                              <ArrowsClockwise weight="fill"></ArrowsClockwise>
                              <span>반복</span>
                            </div>
                            <div className="flex items-center h-8 px-4 mr-2 rounded-lg text-zinc-400 bg-slate-100">
                              <span>반복 설정</span>
                              <span className="ml-2 text-zinc-800">
                                {jobRepeat.frequency === '일' && <div>{jobRepeat.interval} 일 마다 실행</div>}
                                {jobRepeat.frequency === '주' && (
                                  <div>
                                    {jobRepeat.interval} 주 마다 {jobRepeat.dayOfWeek} 에 실행
                                  </div>
                                )}
                                {jobRepeat.frequency === '월' && <div>매월 {jobRepeat.dayOfMonth === 'LAST' ? '마지막' : jobRepeat.interval} 일 마다 실행</div>}

                                {jobRepeat.frequency === '년' && (
                                  <div>
                                    {formatUtil.getKorDate(String(plan.planDate))}
                                    부터 {jobRepeat.interval} 년 마다 실행
                                  </div>
                                )}
                              </span>

                              <span className="ml-6">반복 종료</span>
                              <span className="ml-2 text-zinc-800">
                                {jobRepeat.endType === 'COUNT' && <div>{jobRepeat.endCount} 회 반복 후 종료</div>}
                                {jobRepeat.endType === 'DATE' && <div>{formatUtil.getKorDate(String(jobRepeat.endDate))} 에 종료</div>}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center gap-1 font-medium text-zinc-400">
                            <Lightning weight="fill" />
                            <span>일회성</span>
                          </div>
                        )}
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운송일자</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.planDate, '-')}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                  <FlexTable title={'스케줄 정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>스케줄 번호</FlexTableHeader>
                      <FlexTableData>{jobUnit?.id}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운송유형</FlexTableHeader>
                      <FlexTableData>{type[jobUnit?.type]}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운송물품</FlexTableHeader>
                      <FlexTableData>
                        {jobUnit?.jobPlanPackageName ? (
                          <Chip size="sm" variant="flat" className="text-default-500">
                            {jobUnit?.jobPlanPackageName}
                          </Chip>
                        ) : (
                          '-'
                        )}
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>상차지</FlexTableHeader>
                      <FlexTableData>{jobUnit?.pickupPlaceName}</FlexTableData>
                      <FlexTableHeader>하차지</FlexTableHeader>
                      <FlexTableData>{jobUnit?.deliveryPlaceName}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>단가</FlexTableHeader>
                      <FlexTableData>
                        <div className="flex items-center gap-1">
                          <span className="text-default-400">단가</span>
                          <span>{formatUtil.getNumber(jobUnit?.price)}원</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-default-400">기타금액</span>
                          <span>{formatUtil.getNumber(jobUnit?.price2)}원</span>
                        </div>

                        <div className="flex items-center gap-1">
                          <span className="text-default-400">총 단가</span>
                          <span className="font-medium">{formatUtil.getNumber(Number(jobUnit.price || 0) + Number(jobUnit.price2 || 0))}원</span>
                        </div>
                      </FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    className="mr-auto"
                    isLoading={isLoading}
                    onPressEnd={() => {
                      if (plan.planStatus === 'READY' || plan.planStatus === 'PICKUP' || plan.planStatus === 'DELIVERY') {
                        callAlert(`${status[plan.planStatus]}인 스케줄은 삭제가 불가합니다. 삭제를 원하시면 배차 취소 후 다시 시도 해주세요.`);
                        return;
                      }
                      if (jobRepeat?.id) {
                        onOpenConfirm();
                      } else {
                        requestConfirm({
                          message: '삭제 하시겠습니까??',
                          onConfirm: () => {
                            handlerDelete();
                          },
                        });
                      }
                    }}
                  >
                    삭제
                  </Button>
                  <Button color="default" onPress={onCloseView}>
                    닫기
                  </Button>
                  <Button color="primary" isLoading={delPlan.isPending} onPressEnd={handlerUpdatePage}>
                    수정
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <AlertModal />
      </>

      <Modal isDismissable={false} isOpen={isOpenConfirm} onOpenChange={onOpenChangeConfirm} size="xl">
        <ModalContent>
          {close => (
            <>
              <ModalHeader className="flex flex-col gap-1"> 일정을 삭제할까요?</ModalHeader>
              <ModalBody>
                <RadioGroup value={deleteType} onValueChange={setDeleteType}>
                  <Radio value="THIS">이 일정만 삭제합니다.</Radio>
                  <Radio value="AFTER">이 일정과 앞으로의 일정을 삭제합니다.</Radio>
                </RadioGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseConfirm}>
                  닫기
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    handlerDelete();
                  }}
                >
                  확인
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalView;
